<template>
<div>
  <v-app-bar app height="64px" class="head-section" color="white" dense dark>
    <v-menu v-if="hamburgerShow">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" @click="menuSlected()">
          <v-icon color="secondary" style="font-size: 30px">mdi-menu </v-icon>
        </v-btn>
      </template>
    </v-menu>
    <v-spacer />
        <!-- <div style="color:red;">{{ $route.name }} | {{$router.currentRoute.path}}</div> -->
    <v-toolbar-title class="hideOmbl">
      <v-img
        alt="sentient logo"
        class="shrink"
        src="@/assets/sentientlogoWithoutTagLine.svg"
        min-width="100"
        max-width="193"
      ></v-img>
    </v-toolbar-title>
    <div class="onlyMbl">
    <v-img
 src="@/assets/mob-logo.png" alt="sentient logo"
     class="shrink" min-width="60"
     max-width="60"></v-img>
 </div>

    <v-spacer />
    <v-menu class="menuProfile pt-6" bottom offset-y
         >
      <template v-slot:activator="{ on, attrs }">
        <div>
        <v-btn 
          icon
          class="white mr-5 pr-6 " height="64"
          v-bind="attrs"
          v-on="on"
          :ripple="false"
        >
          <v-icon color="secondary lighten-1" style="font-size: 44px"
            >mdi-account-circle
          </v-icon>
          <template>
            <div class="d-flex flex-column pl-5 align-start ">
              <div class=" secondary--text text--darken-1 nocap font-weight-bold">
                {{userName}}
              </div>
              <div class="caption nocap secondary--text text--lighten-1">
                {{orgName}}
              </div>
            </div>
            <v-icon color="secondary" style="font-size: 15px" class="pad-align">
              mdi-chevron-down
            </v-icon>
          </template>
        </v-btn>
        </div>
      </template>
      <v-card class="mx-auto proLogout" width="165" tile >
        <v-list>
          <v-list-item-group color="primary" class="profile-sub-menu">
            <v-list-item @click="logout()">
              <v-list-item-avatar>
                <v-icon color="error">mdi-logout</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
  <!-- <navigation class="sideNav" :menuClicked="menuClicked"/> -->
</div>
</template> 
<script>
// import navigation from "./navigation.vue";

export default {
    components: {
     //navigation,
  },
  name: "Header",
   data: () => ({
      menuClicked:true,
      hamburgerShow: window.location.pathname !== '/ownerlisting' ? true : false,
      userName:"",
      orgName:"",
      sessionObj: {},
    }),
  created() {
    this.sessionObj = this.$session.get("user_session");
    this.userName = this.sessionObj.session.userName;
    this.orgName = this.sessionObj.session.org_name;
    localStorage.setItem("apiKey", this.sessionObj.session.userApiKey);
    localStorage.setItem("lang", "EN");
  },
  beforeCreate() {
    if (this.$session.get("session_date") < new Date()) {
      this.$session.destroy();
      window.localStorage.clear();
    }

    if (!this.$session.exists()) {
      this.$session.destroy();
      this.$router.push("/login");
      localStorage.clear();
      window.localStorage.clear();
    }
  },
  methods:{
    logout(){
        this.$session.destroy();
        localStorage.clear();
        this.$router.push("/login");
    },
    menuSlected(){
     this.menuClicked = !this.menuClicked;
     this.$emit('menuClicked',this.menuClicked)
    },
  },
};
</script>
<style lang="scss">
.head-section{
  position: absolute !important;
  top: 0 !important;
  z-index: 20 !important;
}
.profile-sub-menu {
  border: 1px solid var(--error-base);
  border-radius: 5px;
}
.proLogout {
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: var(--v-error-base) !important;
  }
  .v-list-item {
    max-height: 43px;
  }
  .v-list.v-sheet.theme--light {
    padding: 0;
    border: 1px solid red;
    border-radius: 5px !important;
    background-color: white;
  }
  .theme--light.v-card {
    background-color: none !important;
  }
}
.menuProfile{
.v-menu__content {
  margin-top: 64px !important;
  margin-left: 0px !important; 
}
.menuable__content__active{
  top: 64px !important;
}
}
.v-app-bar.v-app-bar--fixed {
  position: fixed !important;
  top: 0 !important;
}

.pad-align{
  padding-right: 140px;
  padding-left: 10px;
}
.onlyMbl {
  position: absolute;
    left: 15%;
    top: 0%;
}
@media only screen and (max-width: 562px){
  .pad-align{
    padding-right: 0px;
    padding-left: 0px;
  }

}
@media only screen and (min-width: 768px){
  .onlyMbl {
    display: none;
  }

}

@media only screen and (min-width: 350px) and (max-width: 767px) {
    .hideOmbl {
      display: none;
    }
  }
</style>
