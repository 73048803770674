<template>

<div class="d-flex flex-column" style="height: 100%">
  <!-- ** Originally from AddOrg.vue, changed to a more descriptive name -->
  <div class="s-css-reset flex-fill mt-0">
    <v-breadcrumbs :items="items" v-if="ownerDrop">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <div class="owner-tlt">
        <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc" v-if="ownerDrop"/>
      </div>
  <v-card elevation="2" class="white py-6 maincontent">
      <h2>
        {{ labels.TITLE }}
      </h2>

      <!-- ---------------------------------- -->
      <!-- Manage organisations button groups -->
      <!-- ---------------------------------- -->
      <v-row class="justify-space-between manageOrg">
        <!--  -->
        <v-btn
          color="tertiary1"
          dark
          class="my-4 nocap addOrg-btn"
          @click="toggleDialog('createOrganisationWindow', true)"
        >
          <v-icon>mdi-bank-plus</v-icon>
          {{ labels.SUB_TITLE }}
        </v-btn>

        <v-text-field  v-if="!showDeletedOrgs"
              class="search-bar sen-bg-white"
              v-model="searchText"
              clearable
               @click:clear="resetSearch(true)"
              solo
              hide-details
              append-icon="mdi-magnify"
              :label="'Search Organisations'"              
            v-on:keyup="findText()"
            ></v-text-field>

<v-text-field  v-if="showDeletedOrgs"
              class="search-bar sen-bg-white"
              v-model="delsearchText"
              clearable
              @click:clear="resetSearch(false)"
              solo
              hide-details
              append-icon="mdi-magnify"
              :label="'Search Organisations'"              
            v-on:keyup="findText()"
            ></v-text-field>
        <Dialog
          v-if="showDialog.show"
          dialog-name="createOrganisationWindow"
          :dialog-title="labels.SUB_TITLE"
          max-width="720px"
        >
          <CreateOrganisation class="px-4" />
        </Dialog>
        <!--  -->
        <!-- Call action from organisationService.js directly -->
        <v-btn
          v-if="orgsToReactivate[0]"
          class="my-4 nocap showDltOrg"
          dark
          color="tertiary2"
          @click="toggleConfirm('reactivateSelectedorgs')"
        >
          <v-icon>mdi-account-reactivate</v-icon>
          {{ labels.SUB_TITLE_RE_ACT }}
        </v-btn>
        <!--  -->
        <v-btn
          text
          class="nocap my-4 showDltOrg"
          color="red"
          v-if="!showDeletedOrgs"
          @click="toggleShowDeletedOrgs"
        >
          <v-icon>mdi-delete-clock-outline</v-icon>
          {{ labels.SUB_TITLE_DEL }}
        </v-btn>
        <v-btn
          text
          class="nocap my-4"
          color="success"
          v-else
          @click="toggleShowDeletedOrgs"
        >
          <v-icon>mdi-office-building</v-icon>
          {{ labels.SUB_TITLE_ACT }}
        </v-btn>
      </v-row>
      <!-- -ABOVE- Manage organisations button groups -->
      <!-- ========================================== -->

      <!-- vieOrgDetails function will route to organisation detial page -->
      <!-- Currently using a dummy page for front-end testing -->
      <!-- Actived Organisations -->
      <v-data-table
        :mobile-breakpoint="0"
        :headers="tableHeaders"
        :items="activeOrgs"  
        class="elevation-0 s-table-beige manageTbl s-table mt-6"
        style="cursor: pointer"
        @click:row="viewOrgDetails"
        v-if="!showDeletedOrgs"
      >
      </v-data-table>
      <!-- Deleted organisations -->
      <v-data-table
        v-else
        :mobile-breakpoint="0"
        :headers="tableHeaders"
        :items="deletedOrgs"      
        class="elevation-0 s-table-beige s-table mt-6"
        style="cursor: pointer"
        show-select
        v-model="orgsToReactivate"
      >
      </v-data-table>
    </v-card>

    <!-- Activate Organisation Confirmation -->
    <!-- ----------------------------------- -->
        <ConfirmBox
            box-name="reactivateSelectedorgs"
            :on-confirm-value="orgsToReactivate"
            :box-title="labels.ORG_REACT_CONFIRM"
            :box-content="labels.ORG_REACT_AREYOU"
            box-class="warning-dialog"
            :confirm-btn-text="labels.OD_RE_ACT"
            :cancel-btn-text="labels.ADD_CANCEL"
            confirm-btn-icon="mdi-account-reactivate"
        >
        </ConfirmBox>
    <!-- -* ABOVE *- Activate Organisation Confirmation -->

    
    <v-overlay :value="loader" z-index="6" color="#cececea6">
      <!-- <v-img
        alt="sentient logo"
        class="shrink mt-1"
        :contain="true"
        src="@/assets/loader.gif"
        width="200"
      ></v-img> -->
      <loaderimg v-if="loader"/>
    </v-overlay>
  </div>
    </div>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api";
// Services
import {
  manageOrgsService,
  organisationService,
} from "../../services/organisationService";
// Components
import Dialog from "../ui/Dialog";
import ConfirmBox from "../ui/ConfirmBox";
import CreateOrganisation from "./CreateOrganisation";
import Footer from "./../Footer.vue";
import loaderimg from "../loaderimg.vue";
import headline from "../headline.vue";
export default defineComponent({
  components: { Dialog, CreateOrganisation,Footer ,ConfirmBox, loaderimg,headline,},
  // data: () => ({
  //   confirmDialog: true,
  // }),
  selectedOwner: "",
  ownerDesc: "",
  methods: {
    changeOwner(obj) {
      this.selectedOwner = obj.ownerName;
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
    },
  },
  created: function () {
    this.selectedOwner = localStorage.getItem("ownerName");
    this.ownerDesc = localStorage.getItem('ownerDesc');
  },
  setup(_, ctx) {
    //SESSION OBJECTS
    const session = ctx.root.$session,
    u_session=session.get("user_session").session;
    const ownerDrop = ref(JSON.parse(localStorage.getItem('ownerDrop')));
    const items = [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: false,
        href: "",
      },
      {
        text: "Platform Setting - Organisations",
        disabled: true,
        href: "",
      },
    ];
 
    items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    window.document.title = "Platform-admin";
    if(u_session.orgType!='main'){
      router.push("/orgdetails");////
    }
    // Use ctx to bring router via composition-api
    const { allOrganisations, reactivateOrgs } = manageOrgsService(),
      {
        dialog,
        toggleDialog,
        showDialog,
        confirmBoxs,
        toggleConfirm,
        getActiveOrgList,
        getdeleteOrgList,
        getOrganisationDetails,
        getFlexi,
        organisation,
      } = organisationService(),
      router = ctx.root.$router,
      labels = ref({}),
      activeOrgs = ref([]),
      deletedOrgs = ref([]),
      showDeletedOrgs = ref(false),
      confirmDialog = ref(false),
      orgsToReactivate = ref([]),
      loader = ref(false),
      searchText=ref(""),
      delsearchText=ref("");
    // get org details
    const getOrg = async () => {
      //active org list binding
      await getActiveOrgList();
      loader.value = true;
      activeOrgs.value = allOrganisations.active_list;
      // in active org list binding
      await getdeleteOrgList();
      deletedOrgs.value = allOrganisations.inactive_list;
      loader.value = false;
    };

    getOrg();

    const getreativeOrgList = async () => {
      confirmDialog.value=true;
      getOrg();
    };

const findText = () =>{    
  if(!showDeletedOrgs.value){
 activeOrgs.value= allOrganisations.active_list.filter(list => list.name.toUpperCase().indexOf(searchText.value.toUpperCase()) !=-1)
  }
   else{
    deletedOrgs.value= allOrganisations.inactive_list.filter(list => list.name.toUpperCase().indexOf(delsearchText.value.toUpperCase()) !=-1)
   }
};
const resetSearch = (searchType)=>{
  if(searchType==true){
    activeOrgs.value=allOrganisations.active_list;
    searchText.value='';
  }
  else{
    delsearchText.value='';
     deletedOrgs.value= allOrganisations.inactive_list;
  }

  
}
    const viewOrgDetails = (org) => {
      console.log("View selected organisation details");
      getOrganisationDetails();
      //alert(JSON.stringify(org));
      localStorage.setItem("orgId", org.id);
      router.push("/orgdetails");
    };

    const toggleShowDeletedOrgs = () => {
      // Init orgsToReactivate
      orgsToReactivate.value = [];
      showDeletedOrgs.value = !showDeletedOrgs.value;
    };

    const tableHeaders = ref([]);
    const getFlexiLabels = async () => {
      // await getFlexi("ORG", session.get("lang"));
      await getFlexi("ORG", localStorage.getItem("lang"));
      labels.value = organisation.labels;
      tableHeaders.value = [
        { text: labels.value.NAME, align: "center", value: "name" },
        { text: labels.value.DESC, align: "center", value: "desc" },
        // { text: 'Created By', align: 'center', value: 'orgCreatedBy' },
        {
          text: labels.value.CREATE_DATE,
          align: "center",
          value: "dateCreated",
        },
        { text: labels.value.STATUS, align: "center", value: "status" },
      ];
    };
    getFlexiLabels();
    return {
      tableHeaders,
      activeOrgs,
      deletedOrgs,
      loader,
      viewOrgDetails,
      showDeletedOrgs,
      toggleShowDeletedOrgs,
      orgsToReactivate,
      reactivateOrgs,
      dialog,
      toggleDialog,
      showDialog,
      getActiveOrgList,
      getdeleteOrgList,
      getreativeOrgList,
      getOrganisationDetails,
      getFlexiLabels,
      organisation,
      labels,
      u_session,
      confirmBoxs,
      toggleConfirm,
      searchText,
      delsearchText,
      findText,
      resetSearch,
      ownerDrop,
      items,
    };
  },
});
</script>

<style lang="scss" scoped>
.search-bar {
    max-width: 460px !important;
    margin: 0 auto !important;
}

/* ---------------------------------- mobile view ---------------------------------- */

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .owner-tlt {
  //@at-root-top: 0px;
  margin-top: 0px;
}
.maincontent {
  margin: 0 !important;
}
.addOrg-btn {
  width: 100%;
}
.showDltOrg {
  padding-left: 0 !important;
}
} 
</style>