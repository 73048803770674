<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>{{ labels.FUN_DETAIL_TIT }}</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text>
      <div class="px-10 msForm">
         <!-- -----------Add Data Schema------------>
        <v-col cols="12" class="px-0 py-6">
          <h2>{{ labels.FUN_DETAIL_TIT }}</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" sm="6">
            <label class="my-4">{{ labels.FUN_NAME }}</label>
            <div>{{rowItem.name}}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <label class="my-4">{{ labels.FUN_CODE }}</label>
            <div>{{rowItem.code}}</div>
          </v-col>
           <v-col cols="12" sm="6">
            <label class="my-4">{{ labels.ADD_DESC }}</label>
            <div>{{rowItem.description1}}</div>
          </v-col>
           <v-col cols="12">
            <label class="my-4">{{ labels.CLASS }}</label>
            <div>Platform Services</div>
          </v-col>
        </v-row>
     
      </div>
    </v-card-text>
    <!-- <v-divider></v-divider> -->
    <v-card-actions>
      <v-spacer></v-spacer>
         <div class="py-5">
      <v-btn class="nocap" @click="closeDetailDialog" text>
        <span class=""> {{ labels.ADD_CANCEL }}</span>
      </v-btn>
      <v-btn class="nocap primary" text @click="funEditOpen()">
        <span class="">
          <v-icon>mdi-square-edit-outline</v-icon>
          {{ labels.EDIT }}
        </span>
      </v-btn>
         </div>
    </v-card-actions>       
  </v-card>
</template>

<script>

export default {
  name: "funDetail",
  props: ['rowItem', 'labels'],
 
  data: () => ({
    text: "",
  }),
  methods: {
    funEditOpen(){
      this.$emit('closeDetailDialog', false)
    },
    closeDetailDialog() {
      this.$emit('closeFunctionDetail', false)
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>