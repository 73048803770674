<template>
  <div class="mbl-msform msForm">
    <v-row>
      <v-col cols="12">
        <label>{{ labels.PRE_NAME }}</label>
        <v-text-field outlined placeholder="Name" v-model.trim="preItem.name"></v-text-field>
        <span v-if="!preItem.name && !isValidForm" style="color:red;">{{ labels.PRE_NAME_VAL }}</span>
      </v-col>

      <v-col cols="12">
        <label>{{ labels.PRE_DES }}</label>
        <v-textarea
          outlined
          placeholder="Long Description"
          name="input-7-1"
          rows="3"
          v-model.trim="preItem.desc"
        ></v-textarea>
      </v-col>
      <v-col class="fun-dd" cols="12" md="4" lg="4" xl="4">
          <label>{{ labels.PRE_FNS }}</label>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark
              block             
              v-bind="attrs"
              v-on="on"
              class="nocap justify-space-between" color="tertiary1"
            >
             {{ labels.PRE_FNS }}
              <v-icon style="margin: 0"> mdi-menu-right </v-icon>
            </v-btn>
          </template>

          <!-- Group selection items -->
          <v-list style="max-height: 60vh;">
            <v-list-item v-for="(item, index) in funcList" :key="index"
            v-show="!checkExisting(item.name, preItem.functions)">
              <v-checkbox  color="tertiary1"
              v-model="preItem.functions"
              :value="item"
              >
                <template v-slot:label>
                  <span class="black--text">{{item.name}}</span>
                </template>
              </v-checkbox>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      
      <v-col cols="12">
          <v-divider class="my-4"></v-divider>
          <div class="mb-4">
            <v-chip
              class="ma-1"
              v-for="(fun, index) in preItem.functions"
              :key="index"
              @click:close="removeArrayItem(fun, index)"
              close
              color="tertiary1"
              dark
            >
              <span v-if="fun.value!=undefined">
                {{ fun.value }}
              </span>
              <span v-else>
                {{ fun.name}}
              </span>
            </v-chip>
          </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import API_Util from '../../services/util/API_Util';
export default {
  name: "addpredefined",
  props: ['preItem','funcList', 'isValidForm', 'labels'],
  data: () => ({
    isExist: false,
    func_list: [],
    typeField: [
      {
        text: "POS",
      },
      {
        text: "Referral",
      },
    ],
    chip1: true,
    itemsField: [
      {
        text: "1-12 months",
      },
      {
        text: "Pre-defined End Date",
      },
    ],
    msType: ["Atomistic", "Pipeline"],
    titleRules: [
      v => !!v || 'Title is required',
      v => (v && v.length <= 10) || 'Title must be less than 10 characters',
    ],
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    }
  },
  methods: {
    // getFunctions(obj){

    //   if(this.preItem.id > 0){
    //     var modifiedFuncArray = []
    //     obj.forEach((value) => {
    //       modifiedFuncArray.push({"function_id":value.fId, "id":0, "name":value.fName})
    //     })
    //     obj = modifiedFuncArray
    //   }
    //   return obj
    // },
    removeArrayItem(item, index) {
      this.preItem.functions.splice(index, 1);
      const headers = { "Content-Type" : "application/json;charset=UTF-8", "x-api-key" : this.apiKey};
      var delete_fun = {"ids" : item.id}
      API_Util.URLS.axios
      .post(API_Util.URLS.deleteAssignFunc, delete_fun, {headers})
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => console.log(err));
    },
    checkExisting(name, data){
      var isExist = false;
      if(data != undefined && data.length > 0){
        data.forEach((fun) => {
          if(name == fun.name){
            isExist = true;
          }
        })
        return isExist;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
.v-menu__content {
  margin-top: 0px !important;
  margin-left: 0px;
}

/* ---------------------------------- mobile view ---------------------------------- */
@media only screen and (min-width: 350px) and (max-width: 767px) {
  .fun-dd {
    padding: 0;
  }
}
</style>