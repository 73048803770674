<template>
  <!-- About This Component -->
  <!-- This component create a single button with an link -->
  <div>
    <a :href="'//' + path" target="_blank" v-if="external">
      <v-btn text elevation="0" class="nocap">
        <v-icon>{{ icon }}</v-icon>
        {{ label }}
      </v-btn>
    </a>

    <router-link :to="path" v-else>
      <v-btn text elevation="0" class="nocap">
        <v-icon>{{ icon }}</v-icon>
        {{ label }}
      </v-btn>
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    path: { type: String, default: '' },
    icon: { type: String, default: '' },
    label: { type: String, default: 'Back To All Organisation List' },
    external: { type: Boolean, default: false }
  },
  setup() {
    return {}
  }
})
</script>

<style lang="scss" scoped></style>