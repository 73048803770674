<template>
  <div class="container">
    <v-card :elevation="hover ? 24 : 6" class="px-4">
      <div class="px-10 py-4">
        <div class="py-6">
          <h2 class="text-left">Owner Details</h2>
          <v-divider class="my-4"></v-divider>
        </div>

        <v-row>
          <v-col cols="12" sm="6">
            <label>Owner Name</label>
            <v-text-field outlined readonly></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <label>Organisation Name</label>
            <v-text-field outlined readonly></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <label>Admin User Name</label>
            <v-text-field outlined readonly></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <label>Admin Password</label>
            <v-text-field
              outlined
              readonly
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              @click:append="show1 = !show1"
              hint="At least 8 characters"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <label>Description</label>
            <v-textarea outlined name="input-7-1" rows="3"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <label class="font-weight-bold">Functions :</label>
            <div>
              <span
                ><v-btn class="py-6 my-2 px-5 nocap sen-success white--text" @click="openFun('create')"
                  ><v-icon class="pr-3">mdi-book-plus-multiple </v-icon> Create
                  / Clone Functions</v-btn
                ></span
              >
            </div>
            <v-divider class="mb-6 mt-10"></v-divider>
          </v-col>
        </v-row>
        <v-row class="mt-1">
          <!-- -----------------Internal Functions----------------------------------- -->
          <v-col cols="12" md="6">
            <label>Internal Functions</label>
            <v-menu
              right
              offset-x
              :close-on-content-click="false"
              class="white"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="primary"
                  :ripple="false"
                  class="nocap py-6 justify-start"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  style="width: 100%"
                >
                  <span class="grey--text lighten-6"
                    >Select Internal Functions</span
                  >
                  <v-icon style="margin-left: auto"> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-container v-if="!allSelectedInt">
                <v-row align="center" justify="start">
                  <v-col v-if="!allSelectedInt" cols="12">
                    <v-text-field
                      ref="search"
                      v-model="search"
                      full-width
                      hide-details
                      label="Search"
                      single-line
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider v-if="!allSelectedInt"></v-divider>

                <v-list>
                  <template v-for="item in categoriesInternal">
                    <v-list-item
                      v-if="!selectedInt.includes(item)"
                      :key="item.text"
                      :disabled="loading"
                      @click="selectedInt.push(item)"
                    >
                      <v-list-item-avatar>
                        <v-icon :disabled="loading" v-text="item.icon"></v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-container>
            </v-menu>
            <div class="shrink mt-4">
              <v-chip
                v-for="(selection, i) in selectionsInt"
                class="ma-1 pa-4"
                :key="selection.text"
                :disabled="loading"
                close
                @click:close="selectedInt.splice(i, 1)"
              >
                {{ selection.text }}
              </v-chip>
            </div>
          </v-col>
          <!-- -----------------Microservices------------------------- -->
          <v-col cols="12" md="6">
            <label>Microservices</label>
            <div
              class="d-flex justify-space-between justify-center align-center"
            >
              <div style="width: 100%">
                <v-menu
                  right
                  offset-x
                  :close-on-content-click="false"
                  class="white"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="primary"
                      :ripple="false"
                      class="nocap py-6 justify-start"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      style="width: 100%"
                    >
                      <span class="grey--text lighten-6"
                        >Select Microsservices</span
                      >
                      <v-icon style="margin-left: auto"> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-container v-if="!allSelectedMs">
                    <v-row align="center" justify="start">
                      <v-col v-if="!allSelectedMs" cols="12">
                        <v-text-field
                          ref="search"
                          v-model="search"
                          full-width
                          hide-details
                          label="Search"
                          single-line
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>

                    <v-list>
                      <template v-for="item in msSelect">
                        <v-list-item
                          v-if="!selectedMs.includes(item)"
                          :key="item.text"
                          :disabled="loading"
                          @click="selectedMs.push(item)"
                        >
                          <v-list-item-avatar>
                            <v-icon
                              :disabled="loading"
                              v-text="item.icon"
                            ></v-icon>
                          </v-list-item-avatar>
                          <v-list-item-title
                            v-text="item.text"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-container>
                </v-menu>
              </div>
            </div>
            <div class="shrink mt-4">
              <v-chip
                v-for="(selection, i) in selectionsMs"
                class="ma-1 pa-4 sen-chocolate white--text"
                :key="selection.text"
                :disabled="loading"
                close
                @click:close="selectedMs.splice(i, 1)"
              >
              <span @click="openFun('edit')">
                <v-icon left class="ma-1 white--text mr-2"
                  >mdi-pencil-box-multiple
                </v-icon>
                {{ selection.text }}
                </span>
              </v-chip>
            </div>
          </v-col>
          <!-- -----------------End Microservices------------------------- -->
        </v-row>
        <v-divider class="mt-6"></v-divider>
      </div>
      <div class="text-center pt-6 pb-10">
        <span class="px-2"
          ><v-btn class="nocap" x-large text>Cancel</v-btn></span
        >
        <span class="px-2"
          ><v-btn
            @click.stop="ownerdialog = true"
            class="nocap sen-success white--text"
            x-large
            >Create</v-btn
          ></span
        >
      </div>
      <v-dialog v-model="ownerdialog" max-width="450">
        <v-card>
          <v-card-title class="text-h5">
            <div class="text-center col-12">
              <v-icon style="font-size:60px" class="sen-success-text">mdi-check-circle </v-icon>
              <div class="pt-4">
                <h4 class="pb-4 grey--text text--darken-4">Congratulations</h4>
                <p style="font-weight: normal !important; font-size:18px;" class="grey--text text--darken-4 mb-0">You have successfully created an owner! </p>
            </div>
            </div>
          </v-card-title>
          <v-card-actions>
            <div class="col-12 text-center pb-5">
              <v-btn
                class="nocap"
                color=" sen-success white--text py-6 px-6"
                @click="ownerdialog = false"
              >
                Close
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <loaderimg />
  </div>
</template> 
<script>
import loaderimg from "./loaderimg.vue";
export default {
  components:{ loaderimg},
   name: "owner",
  data: () => ({
    ownerdialog: false,
    show1: false,
    password: "Password",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`,
    },
    msSelect: [
      {
        text: "ASR",
        icon: "mdi-checkbox-blank-outline ",
      },
      {
        text: "ASR",
        icon: "mdi-checkbox-blank-outline ",
      },
      {
        text: "ASR",
        icon: "mdi-checkbox-blank-outline ",
      },
    ],
    items: [
      {
        text: "Nature",
        icon: "mdi-checkbox-blank-outline ",
      },
      {
        text: "Nightlife",
        icon: "mdi-checkbox-blank-outline ",
      },
      {
        text: "November",
        icon: "mdi-checkbox-blank-outline ",
      },
      {
        text: "Portland",
        icon: "mdi-checkbox-blank-outline ",
      },
      {
        text: "Biking",
        icon: "mdi-checkbox-blank-outline ",
      },
    ],
    loading: false,
    search: "",
    selectedInt: [],
    selectedMs: [],
  }),

  computed: {
    allSelectedInt() {
      return this.selectedInt.length === this.items.length;
    },
    allSelectedMs() {
      return this.selectedMs.length === this.msSelect.length;
    },
    categoriesInternal() {
      const search = this.search.toLowerCase();

      if (!search) return this.items;

      return this.items.filter((item) => {
        const text = item.text.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
    categoriesMS() {
      const search = this.search.toLowerCase();

      if (!search) return this.msSelect;

      return this.msSelect.filter((item) => {
        const text = item.text.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
    selectionsInt() {
      const selections = [];

      for (const selection of this.selectedInt) {
        selections.push(selection);
      }

      return selections;
    },

    selectionsMs() {
      const selections = [];

      for (const selection of this.selectedMs) {
        selections.push(selection);
      }

      return selections;
    },
  },

  watch: {
    selectedInt() {
      this.search = "";
    },
    selectedMs() {
      this.search = "";
    },
  },

  methods: {
    openFun(type){
this.$router.push({ name: 'popup', params:{type:type} });
    },
    next() {
      this.loading = true;

      setTimeout(() => {
        this.search = "";
        this.selectedInt = [];
        this.selectedMs = [];
        this.loading = false;
      }, 2000);
    },
  },
};
</script>
<style lang="scss">
.v-input__append-inner {
  margin-top: 11px !important;
}
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 46px !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
.container {
  max-width: 1224px;
}
.v-menu__content {
  background-color: white;
}
.v-chip .mdi-pencil-box-multiple {
  font-size: 21px !important;
}
.v-btn::before {
  background-color: transparent !important;
}
</style>
