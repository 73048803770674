<template>

  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>{{ labels.PROC_DET }}</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text>
      <div class="px-10 msForm">
        <!-- -----------Basic Info------------>
        <v-col cols="12" class="px-0 pb-5">
          <h2>{{ labels.PROC }}</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12">
            <label class="my-4">{{ labels.TY }}</label>
            <div>{{promoAllData.type}}</div>
          </v-col>

          <v-col cols="12">
            <label class="my-4">{{ labels.CD }}</label>
            <div>{{ promoAllData.code }}</div>
          </v-col>

          <v-col cols="12" sm="6">
            <label class="my-4">{{ labels.S_DA }}</label>
            <div>{{ promoAllData.start_date }} </div>
          </v-col>
          
          <v-col cols="12" sm="6" v-if="promoAllData.end_date">
            <label class="my-4">{{ labels.E_DA }}</label>
            <div>{{ promoAllData.end_date }}</div>
          </v-col>

          <v-col cols="12" v-if="promoAllData.credit">
            <label class="my-4">{{ labels.CRE }}</label>
            <div>{{ promoAllData.credit }}</div>
          </v-col>
           <v-col cols="12" v-if="promoAllData.credit_validity_type">
            <label class="my-4">{{ labels.CREV_T }}</label>
            <div>{{ promoAllData.credit_validity_type }}</div>
          </v-col>

          <v-col cols="12" v-if="promoAllData.credit_validity_value">
            <label class="my-4">{{ labels.CREV_V }}</label>
            <div>{{ promoAllData.credit_validity_value }}</div>
          </v-col>

          <v-col cols="12" v-if="promoAllData.discount_type">
            <label class="my-4">{{ labels.DIS_TY }}</label>
            <div>{{ discount_type_view }}</div>
          </v-col>
          <v-col cols="12" v-if="promoAllData.discount_amount">
            <label class="my-4">{{ labels.DIS_AMT }}</label>
            <div>{{ promoAllData.discount_amount }}</div>
          </v-col>
          <v-col cols="12" v-if="promoAllData.minTopupAmount">
            <label class="my-4">{{ labels.MIN_TOP }}</label>
            <div>{{ promoAllData.minTopupAmount }}</div>
          </v-col>
           <v-col cols="12" v-if="promoAllData.description">
            <label class="my-4">{{ labels.DESC }}</label>
            <div>{{ promoAllData.description }}</div>
          </v-col>
        </v-row>        
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="py-6">
      <v-spacer></v-spacer>
      <v-btn class="nocap" @click="closePromoDetail" text>
        <span class=""> {{ labels.CAN }}</span>
      </v-btn>
      <v-btn class="nocap primary" text @click="promoEditDialog()">
        <span class="">
          <v-icon>mdi-square-edit-outline</v-icon>
          {{ labels.EDI }}
        </span>
      </v-btn>
    </v-card-actions>       
  </v-card>
</template>
<script>
export default {
  name: "promodetail",
  props: ["promoAllData", 'promoType', 'discountType', 'creditValidityType', 'labels'],
  data: () => ({
    discount_type_view: "",
  }),
  created() {
    if (this.promoAllData.id > 0 && this.promoAllData.discount_type) {
      let discount_type_obj = this.discountType.find((obj) => obj.code === this.promoAllData.discount_type, this.promoAllData.discount_type);
      this.discount_type_view = discount_type_obj.value;
    }
  },
  methods: {   
    closePromoDetail() {
      this.$emit('closePromoDetail', false);
    },
    promoEditDialog(){
      this.msDetailDialog=true;
      this.msEdit=true;
      this.$emit('closeDetailDialog', false);
    },
    onUpdate(text) {
      this.text = text;
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>