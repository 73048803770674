<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
  
      <div class="py-2">
                      <h3 class="text-center"> {{ labels.MIC_DET}}
                      </h3>
                      <div class="closeicon-right">
                      <v-icon  @click.prevent="closeMsDetailDialog" class="text-right"
                        >mdi-close-circle-outline
                      </v-icon>
                      </div>
                    </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text>
      <div class="px-10 msForm">
        <!-- -----------Basic Info------------>
        <v-col cols="12" class="px-0 pb-5">
          <h2>Basic Info</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" sm="6">
            <label class="my-4">{{ labels.TIT }}</label>
            <div>{{ microAllData.name }}</div>
          </v-col>
          <v-col cols="12" sm="6" v-if="microAllData.code">
            <label class="my-4">{{ labels.COD }}</label>
            <div>{{ microAllData.code }}</div>
          </v-col>
          <v-col cols="12" sm="6" v-if="microAllData.classText">
            <label class="my-4">{{ labels.MIC_CL }}</label>
            <div>{{ microAllData.classText }}</div>
          </v-col>
          <v-col cols="12" sm="6" v-if="microAllData.fnTypeText">
            <label class="my-4">{{ labels.TYPE }}</label>
            <div>{{ microAllData.fnTypeText }}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <label class="my-4">{{ labels.MIC_URLC }}</label>
            <div>{{ microAllData.urlCode }}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <label class="my-4">Pod Status</label>
            <div class="my-2">
              <span v-if="podloader">Processing...</span>
              <span v-else>{{microAllData.podStatus=='RUNNING' ?'Running':'Stopped'}}</span>
              <v-btn
                v-if="podbutton"
                x-small
                :color="microAllData.podReplica==1?'error':'primary'"
                dark
                class="ml-1"
                @click="  scacleing(microAllData.podReplica);"
            >
              {{microAllData.podReplica==1?'Stop':'Start'}}
            </v-btn>
            <v-progress-circular
                v-if="podloader"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-col>

          <v-col cols="12" v-if="microAllData.iconUrl.length">
            <label class="my-4">{{ labels.MIC_ICO }}</label><br>
              <div class="colorPanel mt-0">
              <div class="colorClass imgBox d-flex align-center">
                <v-img
                  alt="sentient logo"
                  class="shrink rounded mx-auto d-block"
                  style="padding: 0px"
                  :src="microAllData.iconUrl"
                  min-width="80"
                  max-width="36"
                  contain
                  aspect-ratio="2"
                ></v-img>
              </div>
            </div>
          </v-col>

        </v-row>
        <!-- -----------Descriptions ------------>
        <v-col cols="12" class="px-0 py-6" v-if="microAllData.description1 || microAllData.description2 || microAllData.global_desc || microAllData.useCase">
          <h2>{{ labels.DESC }}</h2>
        </v-col>
        <v-row
          v-if="microAllData.description1 || microAllData.description2 || microAllData.global_desc || microAllData.useCase"
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" v-if="microAllData.description1">
            <label class="my-4">{{ labels.SHRT }}</label>
            <div>{{ microAllData.description1 }}</div>
          </v-col>
          <v-col cols="12" v-if="microAllData.description2">
            <label class="my-4">{{ labels.LNG }}</label>
            <!-- <div v-html="microAllData.description2">
            </div> -->
              <markdown-it-vue
                class="md-body text-justify mt-3"
                :content="microAllData.description2"
                :options="options"
              >
              </markdown-it-vue>
          </v-col>
          <v-col cols="12" v-if="microAllData.global_desc">
            <label class="my-4">Global Model Explainability</label>
            <!-- <div v-html="microAllData.global_desc">
            </div> -->
              <markdown-it-vue
                class="md-body text-justify mt-3"
                :content="microAllData.global_desc"
                :options="options"
              >
              </markdown-it-vue>
          </v-col>
          <v-col cols="12" v-if="microAllData.useCase">
            <label class="my-4">{{ labels.USECA }}</label>
            <!-- <div v-html="microAllData.">
            </div> -->
              <markdown-it-vue
                class="md-body text-justify mt-3"
                :content="microAllData.useCase"
                :options="options"
              >
              </markdown-it-vue>
          </v-col>
        </v-row>
        <!-- Microservice Request Types -->
        <v-col cols="12" class="px-0 py-6" v-if="microAllData.requestTypes.length">
          <h2>Microservice Type</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
          v-if="microAllData.requestTypes.length"
        >
          <v-col cols="12" v-if="microAllData.requestTypes.length">
             <label class="my-4">{{ labels.MICRO_TY }}</label>
             <v-card>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>                                
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >{{ labels.MICRO_TY }}</span
                                  >
                                </th>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >MS Version</span
                                  >
                                </th>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >{{ labels.YAM_URL }}</span
                                  >
                                </th>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >{{ labels.MIC_DOCURL }}</span
                                  >
                                </th>

                               
                               
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(req, index) in microAllData.requestTypes" :key="index">
                                <td>{{ req.type }}</td>
                                <td class="main-version">{{ req.version }}<v-badge v-if="req.islatest" color="#fb8e0c"  dot></v-badge></td>
                                <td>{{ req.yamlURL }}</td>
                                <td>{{ req.docsURL }}</td>            
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
          </v-col>
        </v-row>
        <!-- -----------Pricing  Plan------------>
        <v-col cols="12" class="px-0 py-6" v-if="microAllData.pricing.length">
          <h2>Pricing and Plan</h2>
        </v-col>
        <v-row
        v-if="microAllData.pricing.length"
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" v-if="microAllData.pricing.length">
             <label class="my-4">Added Pricing Plan</label>
             <v-col cols="12">
                <v-toolbar elevation="0" class="grey lighten-4" >
                  <v-tabs
                    :mobile-breakpoint="0"
                    v-model="pricing_tier"
                    class="s-border-bottom"
                    active-class="box-color white--text"
                    hide-slider>      
                    <v-tab class="nocap s-border-right col-4" 
                      :href="'#'+req" 
                      v-for="(req, index) in typearr()" :key="index"
                      @click="pricingIndex(index, req)">
                      <span v-if="req == 'sync'">
                        Small File
                      </span>
                      <span v-if="req == 'async'">
                        Large File
                      </span>
                      <span v-if="req == 'stream'">
                        Streaming
                      </span>
                    </v-tab>
                  </v-tabs>
                </v-toolbar>
              </v-col>
            <!-- </v-row> -->
            <!------Tab items----- -->
            <!-- <v-row> -->
              <v-col cols="12">
                <v-tabs-items
                  transition="fade-transition"
                  class="col-12 grey lighten-5"
                  v-model="pricing_tier">
                  <v-tab-item value="sync">
                    <pricingPlanTier :microAllData="microAllData" :labels="labels" :pricingValue="pricingValue" :showDetail="showDetail"/>
                  </v-tab-item>
                  <v-tab-item value="async">
                    <pricingPlanTier :microAllData="microAllData" :labels="labels" :pricingValue="pricingValue" :showDetail="showDetail"/>
                  </v-tab-item>
                  <v-tab-item value="stream">
                    <pricingPlanTier :microAllData="microAllData" :labels="labels" :pricingValue="pricingValue" :showDetail="showDetail"/>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
             <!-- <v-card>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Lower Limit</span
                                  >
                                </th>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Upper Limit</span
                                  >
                                </th>

                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Price Per Unit</span
                                  >
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(tr, index) in microAllData.pricing[0].tier" :key="index">
                                <td>{{ tr.lower }}</td>
                                <td>{{ tr.upper }}</td>
                                <td>{{ tr.cost }}</td>
                            
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card> -->
          </v-col>
        </v-row>

         <!-- -----------Pricing  Plan------------>

        <!-- -----------Add Data Schema------------>
        <v-col cols="12" class="px-0 py-6" v-if="microAllData.dataSchema.length">
          <h2>Data Schema</h2>
        </v-col>
        <v-row
          v-if="microAllData.dataSchema.length"
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" v-if="microAllData.dataSchema.length">
             <label class="my-4">{{ labels.DSCH }}</label>
             <v-card>
                    <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr class="lighten-4 text-center grey--text">
                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">{{ labels.DSCH }}</span>
                  </th>
                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">{{ labels.TYPE }}</span>
                  </th>
                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">{{ labels.ISNUL }}</span>
                  </th>
                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">{{ labels.SIZ }}</span>
                  </th>

                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1"
                      >{{ labels.DESC }}</span
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ds, index) in microAllData.dataSchema" :key="index">
                  <td>{{ ds.name }}</td>
                  <td>{{ ds.type }}</td>
                  <td>{{ ds.isnull }}</td>
                  <td>{{ ds.datalength }}</td>
                  <td>{{ ds.description }}</td>                 
                </tr>               
              </tbody>
            </template>
          </v-simple-table>
                      </v-card>
          </v-col>
        </v-row>
        <!-- -----------Additional Info ------------>
        <v-col cols="12" class="px-0 py-6" v-if="microAllData.dataCollectMethod || parsedMicroSource || microAllData.webLink || microAllData.keywords">
          <h2>Additional Info</h2>
        </v-col>
        <v-row
          v-if="microAllData.dataCollectMethod || parsedMicroSource || microAllData.webLink || microAllData.keywords"
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" sm="6" v-if="microAllData.dataCollectMethod">
            <label class="my-4">{{ labels.MIC_DT_COLL }}</label>
            <div>{{ microAllData.dataCollectMethod }}</div>
          </v-col>
          <v-col cols="12" sm="6" v-if="parsedMicroSource">
            <label class="my-4">{{ labels.MIC_SRC }}</label>
            <div>{{ parsedMicroSource }}</div>
          </v-col>
          <v-col cols="12" sm="6" v-if="microAllData.webLink">
            <label class="my-4">{{ labels.MIC_SRCLK }}</label>
            <div>{{ microAllData.webLink }}</div>
          </v-col>
          <v-col cols="12" v-if="microAllData.keywords">
            <label class="my-4">{{ labels.MIC_KW }}</label>
              <v-combobox
              outlined
              multiple
              v-model="microAllData.keywords"
              append-icon
              chips
              class="tag-input"
              readonly
              >
              </v-combobox>
          </v-col>
          <v-col cols="12" v-if="microAllData.tags.length">
             <label class="my-4">{{ labels.TAGS }}</label>
            <v-card :elevation="hover ? 24 : 2">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.MIC_KE }}</span
                        >
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.MIC_VA }}</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(tag, index) in microAllData.tags" :key="index">
                      <td>{{ tag.key }}</td>
                      <td>{{ tag.value }}</td>                     
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
        <!-- -----------Access Details------------>
        <v-col cols="12" class="px-0 py-6">
          <h2>Access Details</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" v-if="microAllData.accessText">
            <label class="my-4">{{ labels.MIC_ACC }}</label>
            <div>{{ microAllData.accessText }}</div>
          </v-col>
          <v-col cols="12" v-if="microAllData.modelExport">
            <label class="my-4">{{ labels.MIC_MODEL_EXP }}</label>
            <div>{{ microAllData.modelExport }}</div>
          </v-col>

          <!--<v-col cols="12" v-if="microAllData.feature.length">
             <label class="my-4">{{ labels.MIC_FEAT }}:</label>
            <v-card :elevation="hover ? 24 : 2">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.MIC_FEAT_TIT }}</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(fea, index) in microAllData.feature" :key="index">
                      <td>{{ fea.title }}</td>                 
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>-->

        </v-row>
        <!-- -----------Validation Settings------------>
        <v-col cols="12" class="px-0 py-6" v-if="microAllData.mandatoryFields || microAllData.limitFields.length">
          <h2>Validation Settings</h2>
        </v-col>
        <v-row
          v-if="microAllData.mandatoryFields || microAllData.limitFields.length"
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" v-if="microAllData.mandatoryFields">
            <label class="my-4">{{ labels.MAN }}</label>
            <v-combobox
              outlined
              multiple
              v-model="microAllData.mandatoryFields"
              append-icon
              chips
              class="tag-input"
              readonly
              >
              </v-combobox>
              
          </v-col>
          <v-col cols="12" v-if="microAllData.limitFields.length">
             <label class="my-4">{{ labels.LIMTT }}</label>
            <v-card :elevation="hover ? 24 : 2">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.TYPE }}</span
                        >
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.LOW_LIM }}</span
                        >
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.UP_LIM }}</span
                        >
                      </th>

                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.FIEL }}</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(limits, index) in microAllData.limitFields" :key="index">
                      <td>{{ limits.type }}</td>
                      <td>{{ limits.lowerLimit }}</td>
                      <td>{{ limits.upperLimit }}</td>
                      <td>{{ limits.field }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions >
      <div class="py-4">
      <v-btn class="nocap" @click.prevent="closeMsDetailDialog" text>
        <span class="">{{ labels.CAN }}</span>
      </v-btn>
      <v-btn class="nocap primary" text @click="msEditDialog()">
        <span class="">
          <v-icon>mdi-square-edit-outline</v-icon>
          {{ labels.EDI }}
        </span>
      </v-btn>
      </div>
    </v-card-actions>       
  </v-card>
</template>

<script>
import MarkdownItVue from "markdown-it-vue";
import pricingPlanTier from "./msPopUp/pricingPlanTier.vue"
import API_Util from "../services/util/API_Util.js"; 
export default {
  name: "msDetailPage",
  components: {
    MarkdownItVue,
    pricingPlanTier
  },
  props: ['microAllData','msDetailDialog', 'micro_source', 'micro_limits', 'request_type','request_version', 'labels', 'micro_type'],
  data: () => ({
    options: {
      markdownIt: {
        linkify: true,
      },
      linkAttributes: {
        attrs: {
          target: "_blank",
          rel: "noopener",
        },
      },
    },
    radios: 'tiered',
    pricing_tier: 'small',
    pricingValue: 0,
    showDetail: true,
    newType:{},
    arr:[],
    apiKey:"",
    podloader:false,
    podbutton:false,

  }),
  created() {
    this.typearr();
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.checkPodStatus('S');
  },
  methods: {
    typearr(){
      this.arr = this.microAllData.requestTypes;
      const unique = [...new Set(this.arr.map(item => item.type))];
      return this.newType = unique
    },
    pricingIndex(indx){
      this.pricingValue = indx;
    },
    closeMsDetailDialog() {
      this.$emit('closeMsDetailDialog', false);
    },
    msEditDialog(){
      this.msDetailDialog=false;
      this.msEdit=true;
      this.$emit('closeDetailDialog', false);
    },
    onUpdate(text) {
      this.text = text;
    },
    getLimitText(object) {
      let limitObj =  this.micro_limits.find(obj => obj.code === object, object);
      return limitObj.value;
    },
    checkPodStatus(loop){
      if((this.microAllData.podReplica==1 && this.microAllData.podStatus=='RUNNING') || (this.microAllData.podReplica==0 && this.microAllData.podStatus=='STOPPED')){
        this.podbutton=true;
        this.podloader=false;
      }else{
        this.podloader=true;
        this.podbutton=false;
        if(loop=='Repeat'){
          setTimeout(()=>{
          this.getMsDetailPage();
        },15000)
        }
      }
    },

    async scacleing(status){
      this.podbutton=false;
      this.podloader=true;
      let scale="scaledown_deployment";
      if (status==0){
        scale="scaleup_deployment";
      }

      let data={"fun_code" : [this.microAllData.code]}
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.scacleing+scale, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result
        )
        .catch((err) => console.log(err));

        setTimeout(()=>{
          this.getMsDetailPage();
        },15000)
        console.log(response);
    },
    async getMsDetailPage() {
     
      var details = {"fId": this.microAllData.id};
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.infoMicroservice, details, {
          headers: { "x-api-key": this.apiKey },
          params: {},
        })
        .then((response) => response.data)
        .catch((err) => console.log(err));
       var res= response;
       this.microAllData.podStatus=res.podStatus;
       this.microAllData.podReplica=res.podReplica;
       this.checkPodStatus('Repeat');
    }
  },
  computed: {
    parsedMicroSource() {
      if (this.microAllData.source) {
        let micro_source_obj = this.micro_source.find(obj=>obj.code === this.microAllData.source, this.microAllData.source);
        return micro_source_obj.value;
      } else {
        return "";
      }
    }
  }
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
.main-version .v-badge{
  left:8px;
  top:-2px;
}

</style>