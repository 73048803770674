<template>
  <div class="mbl-msform msForm">
    <v-row>     
      <v-col cols="12">
        <label>{{ labels.MIC_ACC }}</label>
        <v-select
          outlined
          v-model="microAllData.access"
          :items="micro_access"
          item-text="value"
          item-value="code"
          placeholder="Select Access Type"
        ></v-select>
      </v-col>
      <!-- <v-col cols="12">
        <label>{{ labels.MIC_ENDP }}</label>
        <v-text-field placeholder="API Endpoints" outlined v-model.trim="microAllData.apiendpoint"></v-text-field>
      </v-col>
      <v-col cols="12">
        <label>{{ labels.MIC_DOCURL }}</label>
        <v-text-field placeholder="https://sentient.io" outlined v-model.trim="microAllData.docUrl"></v-text-field>
      </v-col> -->
      <v-col cols="12" v-if="microAllData.id == 0 || micro_type=='OWN' || micro_type=='DUP'">
        <label>{{ labels.MIC_MODEL_EXP }}</label>
        <v-text-field placeholder="https://sentient.io" outlined v-model.trim="microAllData.modelExport"></v-text-field>
      </v-col>
      <!-- <v-col cols="12" class="px-6" v-if="microAllData.id == 0 || micro_type=='OWN' || micro_type=='DUP'">
        <v-row class="mt-2 text-fields-row secondary lighten-3">
          <v-col cols="12" class="type">
            <div
              class="
                d-flex
                flex-md-row flex-sm-column
                justify-space-between
              "
              > 
              <div class="d-flex flex-column px-2 w100">
                  <label>{{ labels.MIC_FEAT }}</label>
                  <v-text-field outlined v-model.trim="featureObj.title" placeholder="text"></v-text-field>
                  <span v-if="microAllData.feature.find(obj => obj['title'] === featureObj.title)" style="color:red;">
                      {{ labels.MIC_FEAT_TITVAL }}
                  </span>          
              </div>
              <div class="d-flex flex-column">
                <span class="ml-1">
                  <v-btn class="nocap primary white--text" style="height:40px; margin-top:21px;"
                    :disabled="!featureObj.title || microAllData.feature.find(obj => obj['title'] === featureObj.title)"
                    @click.prevent="addFeature(featureObj)"
                    >
                      <v-icon>mdi-plus </v-icon>
                  </v-btn>
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col> -->
      <!-- <v-col cols="12" class="px-0 pt-6" v-if="microAllData.feature.length && (microAllData.id == 0 || micro_type=='OWN' || micro_type=='DUP')">
        <v-card>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    <span class="font-weight-boald subtitle-1">{{ labels.MIC_TXT }}</span>
                  </th>                 
                  <th>
                    <span class="font-weight-boald subtitle-1">{{ labels.DEL }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(feature, index) in microAllData.feature" :key=index>
                  <td>{{ feature.title }}</td>                  
                  <td @click.prevent="deleteFeatures(index)"><v-icon color="error">mdi-trash-can </v-icon></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import API_Util from '../../services/util/API_Util.js';
export default {
  name: "msAccessDetail",
  props: ['microAllData',"validForm", "labels", 'micro_type'],
  data: () => ({
    apiKey: "",
    access: "",
    micro_access: [],
    apiendpoint: "",
    docUrl: "",
    modelExport: "",
    featureObj: {id:0, desc: "",},
    owner: "",
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.owner = localStorage.getItem("owner");
    this.getFunctionAccess();
  },
  methods: {
    // deleteFeatures(idx) {
    //   var featObj = this.microAllData.feature.splice(idx,1);
    //   const headers = {
    //           headers: { "x-api-key": this.apiKey },
    //           params: {},
    //         }
    //   console.log(1111111111,featObj[0].id);
    //   var fun_id = {"ids": featObj[0].id}
    //   if (featObj[0].id > 0) {
    //     API_Util.URLS.axios
    //       .post(
    //         API_Util.URLS.deleteFeatures, fun_id,
    //         headers
    //       )
    //       .then()
    //       .catch((err) => console.log(err));
    //   }
    // },
    // clearFeature(id) {
    //   if (this.featureObj.id > 0) {
    //     this.featureObj={id:id,}
    //   } else {
    //     this.featureObj={id:0, desc: '',}
    //   }

    // },
    // addFeature(data) {
    //   this.microAllData.feature.push(data);
    //   this.featureObj = {id:0, desc: "",};
    // },
    async getFunctionAccess() {
     var data = {
      "owner_id":localStorage.getItem("owner"),"lang":"EN","code":"ACCESS_TYPE"
    };
    let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'x-api-key': this.apiKey
            }
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
        this.micro_access = response;
    },
  },
  watch: {
    microAllData(data) {
      this.$emit('microDataSubmitted', data);
    },
    micro_access() {
      if (this.microAllData.id > 0) {
        if (this.microAllData.access) {
          let micro_access_obj = this.micro_access.find(obj => obj.value === this.microAllData.access, this.microAllData.access);
          this.microAllData.access = micro_access_obj.code;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>