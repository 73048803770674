import { computed, reactive } from '@vue/composition-api';
import axios from 'axios';
import APIUTIL from '../services/util/API_Util.js'


const apiKey = localStorage.getItem('apiKey');
const owner = localStorage.getItem('owner');

const postMethod = (apiKey, data, url) => {
    return axios
        .post(url, data, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'x-api-key': apiKey
            }
        })
        .then((response) => response);
};

// const getMethod = (apiKey, url, params) => {
//     return axios
//         .get(url, {
//             params: params,
//             headers: {
//                 'Content-Type': 'application/json;charset=UTF-8',
//                 'x-api-key': apiKey
//             }
//         })
//         .then((response) => response);
// };
// -------------------------------------- //
// *** Storage of data (global state) *** //
// Data below are supposed to be updated by API calls and functons from below Service function
const organisation = reactive({
    labels:{},
    details: {},
    userData: {},
    groupfunctionsList: {},
    groupuserList: {},
    name:"Arun",
    // Group shouldn't be an array, to change to object ordered by group ID
    groups: [],
    users: [],
    deactiveUsers: [],
    functions: [],
    originalfunctions:[],
    orgfunctions:[],
    daSettings: {
        accountType: '',
        startDate: '2021-03-14',
        endDate: '',
        minCommissionValue: '',
        commissionPercentage: 15,
        feePaid: false,
        paymentDate: '2021-03-14',
        sdkHosting: '',
        sdkEndpoint: ''
    },
    userQuata:true,
});


const stage = reactive({
    // Temporary data holder for org functions
    orgFunctions: {}
});


// For ManageOrganisation components
const allOrganisations = reactive({});

// Controls all the alert dialogs
const dialogs = reactive({
    orgManageUsersAddUser: false,
    manageGroups_groupDetail: false,
    manageGroups_createGroup: false,
    manageGroups_preGroup: false,
    createOrganisationWindow: false,
});

// Controls all the alert dialogs
const confirmBoxs = reactive({
    reactivateSelectedorgs: false,
    deleteOrg:false,
    resetUsersPassword:false,
    deleteSelectedUsers: false,
    reactivateSelectedUsers:false,
    deleteSelectedGroups:false,
    removeGroupFromUser:false,
    regenAPIkeysuccess:false,
});

const showDialog=reactive({show:false});

// Users currently editing on Edit User tab
const currentEditingUser = reactive({
    details: {},
    // Example: details : {}
    groupDetails: [],
    functionDetails: [],
    usergroupDetails: [],
});

// Container all functions to assign to user or group or organisation
const functionsList = reactive({});
const groupsList = reactive({});
const preGroupsList = reactive({});
const allEmailList = reactive({});

// -------------------------------------- //
// *** all main functiosn happen here *** //
const organisationService = () => {
    //*****GET FLEXI LABELS */
    const getFlexi = async (code,lang) => {

        let response = await postMethod(
            apiKey,
            {   
                owner_id:owner,
                scrId: code,
                lang: lang,
            },
            APIUTIL.URLS.getlblbyscr
        )
            .then((response) => response.data.labels)
            .catch((err) => console.log(err));
        organisation.labels=response;
    };

    const organisationCreateUser = async (userDetail) => {     
        
        var currentdate = new Date();
        var datetime =
            '' +
            currentdate.getDate() +
            '/' +
            (currentdate.getMonth() + 1) +
            '/' +
            currentdate.getFullYear() +
            ' @ ' +
            currentdate.getHours() +
            ':' +
            currentdate.getMinutes() +
            ':' +
            currentdate.getSeconds();
        var org_id = localStorage.getItem('orgId');
        var sendEmail;
        var userPassword;
        if (userDetail.setPassword == true) {
            userPassword = userDetail.password;
            sendEmail = 'no';
        }
        if (userDetail.setPassword == false) {
            sendEmail = 'yes';
            userPassword = 'Password123$';
        }

         //SPLIT THE USER GROUP IDS ONLY FOR FORM SUBMIT
         var tempGroup=[];
         userDetail.groups.forEach((group) => {
             tempGroup.push(group.group_id);
         });


        var data = {
            id: 0,
            org_id: org_id,
            user_name: userDetail.email,
            user_password: userPassword,
            user_signin: '',
            email_address: userDetail.emails,
            send_email: sendEmail,
            user_groups: tempGroup,
            date_updated: datetime,
            date_created: datetime,
            updated_by: 2,
            root_user: 'no',
            platform_orgin: window.location.origin
        };      
        let response = await postMethod(
            apiKey,
            JSON.stringify(data),
            APIUTIL.URLS.updateuserinfo
        )
            .then((response) => response.data.results)
            .catch((err) => console.log(err));
        console.log(response);
        location.reload();
    };
    // get all active org list from api
    const getActiveOrgList = async () => {
        var owner_id = localStorage.getItem('owner');
        var data ={owner_id: owner_id};
        let response = await postMethod(apiKey, data, APIUTIL.URLS.getOrgList)
            .then((response) => response.data.results)
            .catch((err) => console.log(err));
        allOrganisations.active_list = response;
        getUserQuata();
    };

    // get all in active org list from api
    const getdeleteOrgList = async () => {
        let response = await postMethod(apiKey,{}, APIUTIL.URLS.getDeletedOrgList)
            .then((response) => response.data.results)
            .catch((err) => console.log(err));

        allOrganisations.inactive_list = response;
    };
	
    const getdasettingdetails = async () => {
        var org_id = localStorage.getItem('orgId');
            var getdadata = {"orgId":org_id};

            let getdaresponse = await postMethod(
                apiKey,
                getdadata,
                APIUTIL.URLS.getsetting
            )
            .then((getdaresponse) => getdaresponse)
            .catch((err) => console.log(err));

            organisation.daSettings = getdaresponse.data.result;

            let plans=[{name:'Basic',value:'DA_PT_BASIC'},{name:'Starter',value:'DA_PT_STARTER'},{name:'Premier',value:'DA_PT_PREMIER'},{name:'Poinner',value:'DA_PT_PIONEER'}];
            plans.forEach((el)=>{
                if(el.value==organisation.daSettings.planType){
                    organisation.daSettings.planString=el.name;
                }
            })
            // console.log("getdaresponse : "+JSON.stringify(getdaresponse));
    };


    const getuserInfo = async () => {
        var userID = localStorage.getItem('session_currentuserid');
        var data = {user_id: userID};
        let functionResponse = await postMethod(apiKey, data ,APIUTIL.URLS.getuserinfo)
            .then((response) => response.data.results)
            .catch((err) => console.log(err));
        // console.log('getuserInfo response ' + JSON.stringify(functionResponse));
        currentEditingUser.functionDetails = [];
        currentEditingUser.functionsCount = 0;
        functionResponse.direct_function.forEach((functions) => {
            currentEditingUser.functionDetails.push({name:functions.value,id:functions.id});
        });
        currentEditingUser.functionsCount = currentEditingUser.functionDetails.length;
        currentEditingUser.usergroupDetails = [];
        functionResponse.group_function.forEach((groupfunction) => {
            currentEditingUser.usergroupDetails.push(groupfunction);
        });

        currentEditingUser.groupDetails=[];
        functionResponse.groups.forEach((group) => {
            currentEditingUser.groupDetails.push(group);
        });
        currentEditingUser.details.apikey = functionResponse.user_api_key;
        //functionsList.allFunctionList = functionResponse;

        
    };

    //All Functions List for Org create
    
    const getfunctionList = async () => {
        var org_id = localStorage.getItem('orgId');
        var owner_id = localStorage.getItem('owner');
        var data ={owner_id: owner_id,org_id:org_id};
        let functionResponse = await postMethod(apiKey, data, APIUTIL.URLS.getfunctionlist)
            .then((response) => response.data.results)
            .catch((err) => console.log(err));
        functionsList.allFunctionList = functionResponse;
    };

    //Org Functions List for assign to user
    const getassignFunctionList = async () => {
        var org_id = localStorage.getItem('orgId');
        var data ={org_id:org_id}
        organisation.functions=[];
        organisation.originalfunctions=[];
        organisation.orgfunctions=[];
        let functionResponse = await postMethod(apiKey, data, APIUTIL.URLS.getorgassignfunctions)
            .then((response) => response.data.result)
            .catch((err) => console.log(err));
            
        functionsList.assignOrgFunctions = functionResponse;

        functionResponse.forEach((fun) => {
            organisation.functions.push({id:fun.id,value:fun.name,type:fun.type});
        });
        functionResponse.forEach((fun) => {
            organisation.originalfunctions.push({id:fun.id,function_name:fun.name,type:fun.type});
        });
        functionResponse.forEach((fun) => {
            organisation.orgfunctions.push({id:fun.id,value:fun.name,type:fun.type});
        });
        organisation.functionscount=organisation.originalfunctions.length;
        
    };

     //Add/delete User Direct Function
     const updateUserDirectFunctions = async (details,deleteObjects) => {
        //delete function objects
        deleteDirectFunctions(deleteObjects);

        var userid = localStorage.getItem('session_currentuserid');
        let funData= details.functionDetails;
        funData.forEach(async (fun) => {
            var data = {
                user_id:userid,
                function_id:fun.function_id
            };
            if(fun.function_id!=undefined){
                let response = await postMethod(apiKey, data, APIUTIL.URLS.addusertofunction)
                    .then((response) => response.data.result)
                    .catch((err) => console.log(err));
                console.log(response);
            }
        });
        getuserInfo();
    };

    const deleteDirectFunctions = async (deleteObjects) => {
        deleteObjects.forEach(async (fun) => {
            var data={ user_function_id:fun.id};
                let response = await postMethod(apiKey, data, APIUTIL.URLS.removeuserfromfunction)
                    .then((response) => response.data.result)
                    .catch((err) => console.log(err));
                console.log(response);
        });
        //getuserInfo();
    };

const addusertogroup = async (groupIDs) => {
    var userid = localStorage.getItem('session_currentuserid');
    await groupIDs.value.forEach(function(grp){
        var data= {
            user_id: userid,
            group_id: grp.group_id
        };
        let addGroupResp = postMethod(apiKey, data, APIUTIL.URLS.addusertogroup)
            .then((response) => response.status)
            .catch((err) => console.log(err));
            console.log(addGroupResp);    
    })
    setTimeout(()=>{
        getuserInfo();
        getuserList();
    },2000);
    
    };

    const getPreGroupList = async (owner_id) => {
        var data= {"owner_id":owner_id};
        let groupResponse = await postMethod(apiKey, data,APIUTIL.URLS.listpredefinedgroup)
            .then((response) => response.data.data)
            .catch((err) => console.log(err));
        // console.log('preGroupList response ' + JSON.stringify(groupResponse));
        preGroupsList.allGroupList = groupResponse;
    };

    const getGroupList = async () => {
        var org_id = localStorage.getItem('orgId');
        var data = { org_id: org_id };
        let groupResponse = await postMethod(apiKey, data, APIUTIL.URLS.getorggrouplist)
            .then((response) => response.data.result)
            .catch((err) => console.log(err));
        organisation.groups = groupResponse;
        groupsList.allGroupList = groupResponse;
    };

    const getUserQuata = async () => {
        let userResponse = await postMethod(apiKey, {} ,APIUTIL.URLS.checkuserlimit)
            .then((response) => response.data)
            .catch((err) => console.log(err));
        organisation.userQuata = userResponse;
    };
    
    const getuserList = async () => {
        var org_id = localStorage.getItem('orgId');
        var data={ org_id: org_id };
        let response = await postMethod(apiKey, data, APIUTIL.URLS.getuserlist)
            .then((response) => response.data.results)
            .catch((err) => console.log(err));
        organisation.users = response;
        // console.log('getuserList' + JSON.stringify(response));
    getUserQuata();
    };
    
    const getAllOrgEmail = async () => {
        let response = await postMethod(apiKey,{} ,APIUTIL.URLS.getorgemail)
            .then((response) => response.data.results)
            .catch((err) => console.log(err));
            allEmailList.list=response.emails;
            console.log(response);
    };

    const getdeactiveuserList = async () => {
      var org_id = localStorage.getItem('orgId');
      var data=  { org_id: org_id };
      let response = await postMethod(apiKey,data, APIUTIL.URLS.getdeleteduserlist)
          .then((response) => response.data.results)
          .catch((err) => console.log(err));
      organisation.deactiveUsers = response;
    //   console.log('getdeactiveuserList' + JSON.stringify(response));
  };

    // Handles ManageUsersEditUser function, remove user from a group
    const removeFromGroup = async(user) => {
        var data={ user_group_id: user };
        let response = await postMethod(apiKey, data ,APIUTIL.URLS.removeuserfromgroup)
          .then((response) => response.data)
          .catch((err) => console.log(err));
          console.log(response);
          currentEditingUser.groupDetails = currentEditingUser.groupDetails.filter((item) => item.id !== user);
          getuserList();
    };

    // Update Organisation details after user edit data from Organisation Details page
    // Related Component: OrganisationDetails.vue > confirmEditing()
    const updateOrganisationDetail = async (detail) => {
        // API calls to update organisationDetail
        var data = {
            org_id: detail.id,
            email: detail.email,
            description: detail.desc
        };
        console.log(JSON.stringify(data));
        let response = await postMethod(
            apiKey,
            JSON.stringify(data),
            APIUTIL.URLS.editorginfo
        )
            .then((response) => response.data.results)
            .catch((err) => console.log(err));
        console.log(response);
        getOrganisationDetails();
    };

    const deleteOrganisation = async (detail) => {
        var org_id = detail.id;
        var data= { orgId: org_id };
        let response = await postMethod(apiKey, data ,APIUTIL.URLS.deleteorg)
            .then((response) => response.data.result)
            .catch((err) => console.log(err));
        console.log(response);
        location.href="manageorgs";
    };

    // Update current editing user details
    const getCurrentEditingUser = (details) => {
        localStorage.setItem('session_currentuserid', details.user_id);
        // Reset currentEditingUser.groupDetails
        getuserInfo();
        currentEditingUser.details = details;
        
    };
    // Function to simulate the API call to fetch organisation details
    const getOrganisationDetails = async () => {
        var org_id = localStorage.getItem('orgId');
        var data = { org_id: org_id };
        let response = await postMethod(apiKey, data , APIUTIL.URLS.getorginfo)
            .then((response) => response.data.result)
            .catch((err) => console.log(err));
        localStorage.setItem('ctype', response.ctype);
        organisation.details = response;
    };

    // Toggle alert windows
    const toggleDialog = (dialogName,showStatus) => {
        dialogs[dialogName] = !dialogs[dialogName];
        showDialog.show=showStatus;
    };

     // Toggle alert windows
     const toggleConfirm = (boxName) => {
        confirmBoxs[boxName] = !confirmBoxs[boxName];
    };


    // Below function will be triggered when Alert window confirm button clicked
    // Triggered by @click="alertOnConfirmFunctions[alertName](somevalue)"
    const dialogOnConfirmFunctions = {
        orgManageUsersAddUser: () => {          
        },
    };

     // Below function will be triggered when Alert window confirm button clicked
    // Triggered by @click="alertOnConfirmFunctions[alertName](somevalue)"
    const boxOnConfirmFunctions = {
    /** Organisations to be reactivated */
        reactivateSelectedorgs:async (orgs) => {
        let activateorgId = [];
        orgs.forEach((org) => {
            activateorgId.push(org.id);
        });
        var data = {
            orgIds: activateorgId
        };
        let response = await postMethod(
            apiKey,
            JSON.stringify(data),
            APIUTIL.URLS.activateorg
        )
            .then((response) => response.data.results)
            .catch((err) => console.log(err));
        console.log(response);
        // need to call getdeleteOrgList
        location.reload();
        },
        /**DELETE ORGANISATION */
        deleteOrg:async (org) => {
            await deleteOrganisation(org);
        },
        resetUsersPassword: async (emailAddress) => {
            var ctype = localStorage.getItem('ctype');            
            var data = { multipleEmail: true };

            var multiEmails = [];

            emailAddress.forEach((user) => {
                var usersObject = {};
                usersObject['username'] = user.user_name;
                usersObject['user_id'] = user.user_id;
                usersObject['ctype'] = ctype;
                usersObject['domain'] = window.location.origin;
                multiEmails.push(usersObject);
            });
            data['usersEmails'] = multiEmails;

            let response = await postMethod(apiKey, data, APIUTIL.URLS.resetpasswordemail)
                .then((response) => response)
                .catch((err) => console.log(err));
            console.log(response);
            location.reload();
        },
        deleteSelectedUsers: async (userid) => {
            userid.forEach(async (user) => {
                var data = { user_id: user}
                let response = await postMethod(apiKey, data, APIUTIL.URLS.deleteuser)
                    .then((response) => response)
                    .catch((err) => console.log(err));
                console.log(response);
                location.reload();
            });
        },
        reactivateSelectedUsers:async (users) => {
        //     // To make API call here to reactivate deleted users
        //console.log('Reactivate Deleted Users', users);
        let activateuserId = [];
        
        users.forEach((deleteuserid) => {
            activateuserId.push(deleteuserid.user_id);
        });

        var data = {
            user_ids: activateuserId
        };
        let response = await postMethod(apiKey, JSON.stringify(data), APIUTIL.URLS.activateusers)
        .then((response) => response)
        .catch((err) => console.log(err));
        console.log(response);
        location.reload();
        },
        deleteSelectedGroups:(groups) => {
            groups.forEach(function(group){
                var data={ group_id: group.group_id };
                postMethod(apiKey, data, APIUTIL.URLS.deletegroup)
                .then((response) => response.data.result)
                .catch((err) => console.log(err));
            })
            confirmBoxs.deleteSelectedGroups = false;
            setTimeout(() => {
                location.reload();
            },500);
        },
        removeGroupFromUser:(group) => {
            removeFromGroup(group.id);
            confirmBoxs.removeGroupFromUser = false;
        },
        regenAPIkeysuccess:() => {
            confirmBoxs.regenAPIkeysuccess = false;
        },
    };

       //**Referesh API key */
       const refreshApiKey =async () => {
        var data = {
            user_id: parseInt(localStorage.getItem('session_currentuserid')),
            updated_by: 2,
        };
        let response = await postMethod(
            apiKey,
            data,
            APIUTIL.URLS.refreshapikey
        )
            .then((response) => response.data.result)
            .catch((err) => console.log(err));
        console.log(response);
        currentEditingUser.details.apikey=response.new_api_key;
    }

    //**Update User Password */
    const updatePassword =async (pwd) => {
        var data = {
            user_id: parseInt(localStorage.getItem('session_currentuserid')),
            user_password: pwd,
        };
        let response = await postMethod(
            apiKey,
            data,
            APIUTIL.URLS.edituserinfo
        )
            .then((response) => response.data.results)
            .catch((err) => console.log(err));
        console.log(response);
    }


    return {
        // Use computed to prevent user from access to the variable
        currentEditingUser: computed(() => currentEditingUser),
        // usersData,
        organisation,
        getFlexi,
        getOrganisationDetails,
        getActiveOrgList,
        getdeleteOrgList,
        getdasettingdetails,   
        getfunctionList,
        updateUserDirectFunctions,
        deleteDirectFunctions,
        getassignFunctionList,
        getGroupList,
        getPreGroupList,
        getuserList,
        getdeactiveuserList,
        getuserInfo,
        addusertogroup,
        getUserQuata,
        // ManageUsersEditUser.vue
        removeFromGroup,
        // ManageUsers table, Get user data to edit
        getCurrentEditingUser,
        refreshApiKey,
        updatePassword,

        // Controls all the alert dialogs
        dialogs,
        toggleDialog,
        confirmBoxs,
        toggleConfirm,
        boxOnConfirmFunctions,
        showDialog,
        dialogOnConfirmFunctions,
        updateOrganisationDetail,
        deleteOrganisation,
        organisationCreateUser,
        functionsList,
        groupsList,
        preGroupsList,
        stage,
        getAllOrgEmail,
        allEmailList
    };
};
// -* END *- Organisation Service //

// -------------------------------------------- //
// Below service contains all functions for     //
// Organisation Details -> Manage Groups tab    //
// -------------------------------------------- //
const manageGroupsService = () => {

    const getGroupList = async () => {
        var org_id = localStorage.getItem('orgId');
        var data= { org_id: org_id };
        let groupResponse = await postMethod(apiKey, data, APIUTIL.URLS.getorggrouplist)
            .then((response) => response.data.result)
            .catch((err) => console.log(err));
        organisation.groups = groupResponse;
        groupsList.allGroupList = groupResponse;
    };

    const getGroupFunctions = async (details) => {
        var data= { group_id: details.group_id };
    let response = await [postMethod](apiKey, data, APIUTIL.URLS.getgroupinfo)
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
        // console.log("group function details : "+JSON.stringify(response));
        organisation.groupfunctionsList = response.group_function;
        organisation.groupfunctionsCount=response.group_function.length;
        organisation.groupuserList = response.users;

        //REMOVE USER DUPLICATES
        organisation.groupuserList=response.users.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i)
        
    };

    const updateGroup = (details,groupFunctions,delGroupFunctions) => {

        /**ASSIGN GROUP FUNCTION */
        groupFunctions.forEach(function(fun){
            var data= { group_id: details.group_id,function_id:fun.function_id };
            if(fun.function_id!=undefined){
                postMethod(apiKey, data, APIUTIL.URLS.addfunctiontogroup)
                    .then((response) => response.data.result)
                    .catch((err) => console.log(err));
            }
        });

        /**REMOVE GROUP FUNCTION */
        delGroupFunctions.forEach(function(fun){
            var data=  { function_group_id:fun.id };
                postMethod(apiKey, data, APIUTIL.URLS.removefunctionfromgroup,)
                    .then((response) => response.data.result)
                    .catch((err) => console.log(err));
        });

        
    };

    const createGroup = async (newGroup,from) => {
        // Create Promise function to simulate API call
        //alert(JSON.stringify(newGroup));
            var org_id = localStorage.getItem('orgId');
            console.log(org_id)
            var owner_id = localStorage.getItem('owner');
            var currentdate = new Date();
            var gFuns=[];
            var datetime =
                '' +
                currentdate.getDate() +
                '/' +
                (currentdate.getMonth() + 1) +
                '/' +
                currentdate.getFullYear() +
                ' @ ' +
                currentdate.getHours() +
                ':' +
                currentdate.getMinutes() +
                ':' +
                currentdate.getSeconds();
                if(from=='ADD'){
                    newGroup.functions.forEach(function(fun){
                        gFuns.push(fun.function_id);
                    });
                    var data = {
                        id: 0,
                        org_id: org_id,
                        owner_id:owner_id,
                        org_group_name: newGroup.name,
                        description: newGroup.description,
                        date_updated: datetime,
                        updated_by: 2,
                        group_functions: gFuns
                    };
                    return await postMethod(
                        apiKey,
                        JSON.stringify(data),
                        APIUTIL.URLS.updateorggroup
                    );
                }else{
                    newGroup.functions.forEach(function(fun){
                        gFuns.push(fun)
                    });
                    var datas = {
                        id: 0,
                        org_id: org_id,
                        owner_id:owner_id,
                        //org_group_name: newGroup.name,
                        //description: newGroup.description,
                        date_updated: datetime,
                        updated_by: 2,
                        group_functions: gFuns
                    }   
                    return await postMethod(
                        apiKey,
                        JSON.stringify(datas),
                        APIUTIL.URLS.updateorggroup
                    );
                }
    };

return { updateGroup, createGroup, getGroupFunctions ,getGroupList};
};

// ------------------------------------------------ //
// Service function below handles all functions for //
// Organisation Details -> Manage Users Tab         //
// ------------------------------------------------ //
const manageUsersService = () => {
    return { currentEditingUser };
};

// ----------------------------------------------- //
// Managing All Organisations (Super Admin Access) //
// ----------------------------------------------- //

const manageOrgsService = () => {
    // From ManageOrganisations.vue, if user choose reactivate orgs
    // Here will make an API call to update groups state
    
    const createOrganisation = async (detail) => {
        // console.log('Create organisation with the following details');
        // console.log(detail);
        var owner_id = localStorage.getItem('owner');
        var currentdate = new Date();
        var datetime =
            '' +
            currentdate.getDate() +
            '/' +
            (currentdate.getMonth() + 1) +
            '/' +
            currentdate.getFullYear() +
            ' @ ' +
            currentdate.getHours() +
            ':' +
            currentdate.getMinutes() +
            ':' +
            currentdate.getSeconds();

        //SPLIT THE USER GROUP IDS ONLY FOR FORM SUBMIT
        var dataGroup=[];
        detail.groups.forEach((group) => {
            dataGroup.push({groupId:group.id,groupName:group.name});
        });

        var dataFuns=[];
        detail.functions.forEach((fun) => {
            dataFuns.push(fun.function_id);
        });
        
        var data = {
            org_id: 0,
            owner_id:owner_id,
            org_name: detail.name,
            clientId: detail.clientId,
            org_user_name: detail.rootUserName,
            description: detail.description,
            email: detail.email,
            ticket: detail.tickedId,
            date_updated: datetime,
            updated_by: 0,
            function_ids: dataFuns,
            public_key: detail.consumerPublicKey,
            pre_def_groups: dataGroup,
            platform_orgin: window.location.origin
        };
        console.log(JSON.stringify(data));
        let response = await postMethod(
            apiKey,
            JSON.stringify(data),
            APIUTIL.URLS.updateorginfo
        )
            .then((response) => response.data.results)
            .catch((err) => console.log(err));
        console.log(response);
        // need to call getActiveOrgList
        location.reload();
    };

    //**ASSIGN ORG FUNCTION */
    const assignOrgFunction =async (fIds) => {
        var data = {
            orgId: parseInt(localStorage.getItem('orgId')),
            functionId: fIds,
        };
        let response = await postMethod(
            apiKey,
            data,
            APIUTIL.URLS.assignfunction
        )
            .then((response) => response.data.message)
            .catch((err) => console.log(err));
            console.log(response);
    }

     //**REMOVE ORG FUNCTION */
     const removeOrgFunction =async (delId) => {
        var data={ id: delId };
        let response = await postMethod(apiKey, data, APIUTIL.URLS.removefunctionfromorg)
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
        console.log(response)
    }
    const allAssignedFunction =async (orgFunctions) => {
        var newIds=[];
        var deletedObj=[];
        //**IF USER ADD ANY NEW FUNCTIONS */
        orgFunctions.forEach((fun) => {
            if (fun["created_by"]!=undefined){
                newIds.push(fun.function_id);
            }
        });
        //**IF USER DELETE ANY FUNCTIONS */
        organisation.orgfunctions.filter((fun) =>{
            if(!JSON.stringify(orgFunctions).includes(JSON.stringify(fun))){
                deletedObj.push(fun);
            }
        });
        deletedObj.forEach((fun) => {
            removeOrgFunction(fun.id);
        });
        organisation.functions = orgFunctions;
        if(newIds.length>0){
            assignOrgFunction(newIds);
        }
    }

    // Object contains function will be used in reactive componnets
    const updateOrgDetails = {
        // ---------------------------------- //
        // -START- Set organisations function //

        // Test is for testing simulated API call functons, remove it in production
        // setFunctions: (orgFunctions) => {
        //     return new Promise((resolve, reject) => {
        //         // Simulate API calls, replace test with actual API call result in production
        //         const test = true; // Fur success API call test
        //         // const test = false // Fur error API call test
        //         if (test) {
        //             // Simulate success API call
        //             setTimeout(() => {
        //                 // Update new orgFunctions to organisation
        //                 // Make API call to update database
        //                 var newIds=[];
        //                 var deletedObj=[];
        //                 //**IF USER ADD ANY NEW FUNCTIONS */
        //                 orgFunctions.forEach((fun) => {
        //                     if (fun["created_by"]!=undefined){
        //                         newIds.push(fun.function_id);
        //                     }
        //                 });

        //                 //**IF USER DELETE ANY FUNCTIONS */
		// 	organisation.functions.filter((fun) =>{
		// 		if(!JSON.stringify(orgFunctions).includes(JSON.stringify(fun))){
		// 			deletedObj.push(fun);
		// 		}
		// 	});
        //                 deletedObj.forEach((fun) => {
        //                     removeOrgFunction(fun.id);
        //                 });
                        
        //                 organisation.functions = orgFunctions;

        //                 if(newIds.length>0){
        //                     assignOrgFunction(newIds);
        //                 }
        //                 resolve();
        //             }, 1000);
        //         } else {
        //             // Simulate error API call
        //             setTimeout(() => {
        //                 reject('Simulated error API call message');
        //             }, 2000);
        //         }
        //     });
        // },
        // -Above- Set organisations function //
        // ================================== //

        // ------------------------------------ //
        // -START- Set organisation DA seetings //
        setDaSettings: async(newDaSettings) => {
        //console.log("newDaSettings : "+JSON.stringify(newDaSettings));
        var org_id = localStorage.getItem('orgId');
        let plans=[{name:'Basic',value:'DA_PT_BASIC'},{name:'Starter',value:'DA_PT_STARTER'},{name:'Premier',value:'DA_PT_PREMIER'},{name:'Poinner',value:'DA_PT_PIONEER'}];
            plans.forEach((el)=>{
                if(el.name==newDaSettings.accountType){
                    newDaSettings.accountType=el.value;
                }
            })
        var data = {
			"id" : 0,
			"ownerId" : "", 
			"orgId" : org_id,
			"commissionPercentage" : newDaSettings.commissionPercentage,
			"commissionValue" : newDaSettings.minCommissionValue,
			"inviteMemberEmail" : "",
			"memberType" : "CONSUMER_PROVIDER",
			"planType" : newDaSettings.accountType, 
			"startDate" : newDaSettings.startDate,
			"feePaid" : newDaSettings.feePaid,
			"paymentDate" : newDaSettings.paymentDate,
			"durationMonth" : "",
			"sdkEndpoint" : newDaSettings.sdkEndpoint,
			"sdkHosting" : newDaSettings.sdkHosting,
			"createdBy" : ""
			};
            let response = await postMethod(
                apiKey,
                data,
                APIUTIL.URLS.setting
            )
                .then((response) => response)
                .catch((err) => console.log(err));
            console.log(response);

            var getdadata = {"orgId":org_id};

            let getdaresponse = await postMethod(
                apiKey,
                getdadata,
                APIUTIL.URLS.getsetting
            )
                .then((getdaresponse) => getdaresponse)
                .catch((err) => console.log(err));

            organisation.daSettings = getdaresponse.data.result;
            let plansTypes=[{name:'Basic',value:'DA_PT_BASIC'},{name:'Starter',value:'DA_PT_STARTER'},{name:'Premier',value:'DA_PT_PREMIER'},{name:'Poinner',value:'DA_PT_PIONEER'}];
            plansTypes.forEach((el)=>{
                if(el.value==organisation.daSettings.planType){
                    organisation.daSettings.planString=el.name;
                }
            })
            // console.log("getdaresponse : "+JSON.stringify(organisation.daSettings));

            return new Promise((resolve, reject) => {
                const test = true; // Fur success API call test
                // const test = false // Fur error API call test
                if (test) {
                    // Simulate success API call
                    setTimeout(() => {
                        // Update new orgFunctions to organisation
                        // Make API call to update database
                        //organisation.daSettings = newDaSettings;
                        resolve();
                    }, 2000);
                } else {
                    // Simulate error API call
                    setTimeout(() => {
                        reject('Simulated error API call message');
                    }, 2000);
                }
            });
        }
        // -ABOVE- Set organisation DA seetings //
        // ==================================== //
    };

    return {
        allOrganisations,
        createOrganisation,
        updateOrgDetails,
        assignOrgFunction,
        removeOrgFunction,
        allAssignedFunction
    };
};

export {
    organisationService,
    manageGroupsService,
    manageUsersService,
    manageOrgsService,
};
