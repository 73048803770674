import axios from "axios";
import APIUTIL from './util/API_Util.js';

const FlexiService = () => {

    // COMMON GET METHOD
    
    // COMMON POST METHOD
    const postMethod = (apiKey,data,url) => {
        return axios.
            post(
                url,
                data,
                {
                    headers: { "Content-Type": "application/json;charset=UTF-8", "x-api-key": apiKey },
                }
            ).then((response) => response);
    }

    const getLabels = async (apiKey,data) => {
        return await postMethod(apiKey,data,APIUTIL.URLS.getlblbyscr);
    }
    

    const getFlexList = async (apiKey,data) => {
        return await postMethod(apiKey,data,APIUTIL.URLS.flexyListValue);
    }

    const filterErrorList = async (array) => {
        let new_object={};
        array.filter((item) => {
            new_object[item.code]=item.value;
        })
        return new_object;
    }

    return {
        getLabels,
        getFlexList,
        filterErrorList
    }
}

export {
    FlexiService
}
