<template>
  <div class="mbl-msform msForm">
    <v-row>
      <v-col cols="12" sm="6">
        <label>{{ labels.CD }}</label>
        <v-text-field outlined placeholder="Code" v-model.trim="newValue.code" @input="() => (newValue.code = newValue.code.toUpperCase())"></v-text-field>
        <span v-if="!newValue.code && !isValidForm" style="color:red;">{{ labels.CDVAL }}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.VAEN }}</label>
        <v-text-field outlined placeholder="Value (EN)" v-model.trim="newValue.value"></v-text-field>
        <span v-if="!newValue.value && !isValidForm" style="color:red;">{{ labels.VAENVAL }}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.IC_URL }}</label>
        <v-text-field outlined placeholder="ICON URL" v-model.trim="newValue.icon_url"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.PG_URL }}</label>
        <v-text-field outlined placeholder="PAGE URL"
        v-model="newValue.page_url"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.LK_URL }}</label>
        <v-text-field outlined placeholder="LINK URL" v-model.trim="newValue.link_url"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.ORD }}</label>
        <v-text-field rows="1" outlined type="text" placeholder="Order By" v-model.trim="newValue.orderBy" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')"></v-text-field>
        <span v-if="!newValue.orderBy && !isValidForm" style="color:red;">{{ labels.ORDVAL }}</span>
      </v-col>

      <!-- ----------------------------------  -->
      <v-col cols="12" class="secondary lighten-3">
        <div class="d-flex align-center">
          <v-row class="text-fields-row">
            <v-col cols="12" sm="5">
              <label> {{ labels.ADD_ATTRS }}</label>
              <v-text-field outlined placeholder="Additional Attributes" v-model.trim="atr.attribute"></v-text-field>
            </v-col>

            <v-col cols="12" sm="5">
              <label>{{ labels.VAEN }}</label>
              <v-text-field outlined placeholder="Value" v-model.trim="atr.attr_value"></v-text-field>
            </v-col>

            <v-col class="addflexy-vcol" cols="12" sm="2">
              <span class="mt-2">
                <v-btn class="nocap" outlined style="height: 40px"
                  ><v-icon @click.prevent="atr={attribute_id:0,}">mdi-autorenew </v-icon>
                </v-btn></span
              >
              
              <span class="mt-2 ml-2"
                ><v-btn class="nocap primary white--text" style="height: 40px">
                  <v-icon 
                  :disabled="!atr.attribute || !atr.attr_value || attribute_array.find(o => o.attribute === atr.attribute)"
                  @click.stop="add_attr(atr)">mdi-plus</v-icon>
                </v-btn></span
              >
            </v-col>
          </v-row>
        </div>
        
      </v-col>
           <v-col cols="12">
            <v-card :elevation="hover ? 24 : 2">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr v-if="newValue.extra_attributes.length > 0"> 
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.ATTR }}</span>
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.VAEN }}</span
                        >
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.DEL }}</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(at, index) in attribute_array" :key="index">
                      <td>{{at.attribute}}</td>
                      <td>{{at.attr_value}}</td>      
                       <td @click.prevent="deleteExtraAttr(index)"> 
                          <v-icon                    
                            color="error"
                            >mdi-trash-can
                          </v-icon></td>                   
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          
      <!-- ----------------------------- -->
      <!-- ----------------------------------  -->
      
      <v-col cols="12" class="secondary lighten-3">
        <div class="d-flex align-center">
          <v-row class="text-fields-row">
            <v-col cols="12" sm="5">
              <label>{{ labels.LANGS }}</label>
              <v-select
                outlined
                v-model="lang_obj.lang"
                :items="langItems"
                item-text="language"
                item-value="id"
                placeholder="Language"
                item-disabled="disable"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="5">
              <label>{{ labels.TEXT }}</label>
              <v-text-field outlined placeholder="Text" v-model.trim="lang_obj.text"></v-text-field>
            </v-col>

            <v-col class="addflexy-vcol" cols="12" sm="2">
              <span class="mt-2">
                <v-btn class="nocap" outlined style="height: 40px"
                  ><v-icon @click.prevent="lang_obj={id:0,}">mdi-autorenew </v-icon>
                </v-btn></span
              >
              
              <span class="mt-2 ml-2"
                ><v-btn class="nocap primary white--text" style="height: 40px">
                  <v-icon 
                  :disabled="!lang_obj.lang || !lang_obj.text || lang_array.find(o => o.lang === lang_obj.lang)"
                  @click.stop="add">mdi-plus</v-icon>
                </v-btn></span
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
           <v-col cols="12">
            <v-card :elevation="hover ? 24 : 2">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr v-if="newValue.languages.length > 0"> 
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.TR_LA }}</span>
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.TEXT }}</span
                        >
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.DEL }}</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(ta, index) in lang_array" :key="index">
                      <td>{{getLang(ta.lang)}}</td>
                      <td>{{ta.text}}</td>      
                       <td @click.prevent="deleteTrans(ta, index)">
                          <v-icon                    
                            color="error"
                            >mdi-trash-can
                          </v-icon></td>                   
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          
      <!-- ----------------------------- -->
    </v-row>
  </div>
</template>

<script>
import API_Util from '../../services/util/API_Util';
export default {
  name: "addflexyvalue",
  props: ['newValue', 'isValidForm', 'langItems', 'valueInfo', 'labels'],
  data: () => ({
    itemsField: [],
    msType: ["Atomistic", "Pipeline"],
    dropName: [],
    lang: "",
    text: "",
    lang_name: "",
    text_display: "",
    lang_array: [],
    lang_obj: {id:0,},
    atr: {attribute_id:0,},
    attribute_array:[],
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    if(this.newValue.id > 0){
      if(this.newValue.languages !== []){
        this.lang_array = this.newValue.languages;
      }
      if(this.newValue.extra_attributes !== []){
        this.attribute_array = this.newValue.extra_attributes;
      }
      this.newValue.delete_ids = [];
    }
  },
  computed: {},

  methods: {
    add_attr(data){
      this.attribute_array.push(data);
      this.newValue.extra_attributes = this.attribute_array;
      this.atr = {attribute_id:0,};
    },
    add(){
      this.langItems.forEach((rowObj) => {
        if(rowObj.id == this.lang_obj.lang){
          rowObj.disable = true
        }
      })
      this.lang_array.push(this.lang_obj);
      this.newValue.languages = this.lang_array;
      this.lang_obj = {id:0};

      this.langItems.forEach((row) => {
        this.newValue.languages.forEach((obj) => {
          if(obj.lang == row.id){
            obj.lang = row.language
          }
        })
      });
    },
    deleteExtraAttr(idx){
      var del_obj = this.attribute_array.splice(idx, 1);
      del_obj.forEach((id) => {
        if(id.attribute_id > 0){
          this.newValue.delete_ids.push(id.attribute_id);
        }
      })
    },
    deleteTrans(item, index){
      this.lang_array.splice(index, 1)
      this.langItems.forEach((row) => {
        if(isNaN(item.lang)){
          if(row.language == item.lang){
            row.disable = false
          }
        }
        else{
          if(row.id == item.lang){
            row.disable = false
          }
        }
      })
      var ids = {"ids": item.id}
      API_Util.URLS.axios
      .post(API_Util.URLS.deleteTransValue, ids,
      {
        headers : { "x-api-key" : this.apiKey }
      })
      .then((response) => response)
      .catch((err) => console.log(err));
    },

    getLang(langId){
      var lang_name = langId
      this.langItems.forEach((row) => {
        if(row.id == langId){
          row.disable = true
          lang_name = row.language
        }
      })
      return lang_name
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
.v-menu__content {
  margin-top: 0px !important;
  margin-left: 0px;
}
</style>
