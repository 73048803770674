<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
                      <h3 class="text-center">{{ labels.NAME_DET }}</h3>
                      <div class="closeicon-right">
                      <v-icon @click="closeDetailDialog" class="text-right"
                        >mdi-close-circle-outline
                      </v-icon>
                      </div>
                    </div>   
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text>
      <div class="px-10 msForm">
        <!-- -----------Basic Info------------>
        <v-col cols="12" class="px-0 pb-5">
          <h2>{{ labels.NAME_DET }}</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12">
            <label class="my-4">{{ labels.PRE_NAME }}</label>
            <div>{{preItem.name}}</div>
          </v-col>

          <v-col cols="12" sm="6">
            <label class="my-4">{{ labels.PRE_DES }}</label>
            <div>{{preItem.desc}}</div>
          </v-col>

          <v-col cols="12">
            <label class="my-4 pr-4">{{ labels.PRE_FNS }}</label>
                <span class="my-4 pr-4">{{ labels.PRE_FNS_ATT }} {{preItem.functions.length}}</span>
            <div class="mt-4">
              <label class="my-4 pr-4">{{ labels.PRE_ASS_FNS }}</label>
          
              <v-divider class="my-4"></v-divider>
              <v-chip
                class="ma-1 tertiary1 tertiary1--text text--darken-4"
                v-for="(item, index) in preItem.functions"
                :key="index"
                >{{item.name}}
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="py-6">
      <v-spacer></v-spacer>
      <v-btn class="nocap" @click="closeDetailDialog" text>
        <span class=""> {{ labels.CAN }}</span>
      </v-btn>
      <v-btn class="nocap primary" text @click="predefinedEdit()">
        <span class="">
          <v-icon>mdi-square-edit-outline</v-icon>
          {{ labels.EDI }}
        </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "predefineddetail",
  props: ['preItem', 'labels'],
  data: () => ({
    checkbox: true,
    iconlist: [],
    text: "",
    chip1: true,
    chip2: true,
    itemsSource: [
      {
        text: "Nature",
        icon: "mdi-pencil-outline ",
      },
      {
        text: "Nightlife",
        icon: "mdi-pencil-outline ",
      },
    ],
    msType: ["Atomistic", "Pipeline"],
  }),
  methods: {
    closeDetailDialog() {
      this.$emit('closePredefinedDetail', false);
    },
    predefinedEdit() {
      // this.msDetailDialog=false;
      // this.msEdit=true;
      this.$emit("closeDetailDialog", false);
    },

    onUpdate(text) {
      this.text = text;
    },
    onChangefile(fieldname, imageref) {
      this[fieldname] = [...this.$refs[fieldname].files];

      if (fieldname != "imagelist" && this[fieldname].length > 1) {
        this[fieldname].splice(0, this[fieldname].length - 1);
      }

      for (let i = 0; i < this[fieldname].length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.$refs[`${imageref}` + parseInt(i)][0].src = reader.result;
        };
        reader.readAsDataURL(this[fieldname][i]);
      }
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>