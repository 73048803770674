<template>
  <div class="mbl-msform msForm">
    <v-row>
      <v-col cols="12" sm="6">
        <label>{{ labels.TIT }}</label>
        <v-text-field
          outlined
          placeholder="Microservice Title"
          v-model.trim="microAllData.name"
        ></v-text-field>
        <span v-if="!microAllData.name && !validForm" style="color:red;">{{ labels.TIT_VAL }}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.MIC_COD }}</label>
        <v-text-field
          outlined
          placeholder="Microservice Code"
          v-model.trim="microAllData.code"
          @copy.prevent
          @paste.prevent
          @input="() => (microAllData.code = microAllData.code.toUpperCase())"
          onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
        ></v-text-field>
        <span v-if="!microAllData.code && !validForm" style="color:red;">{{ labels.MIC_CODVAL }}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.MIC_CL }}</label>
        <v-select
          outlined
          v-model="microAllData.clas"
          :items="micro_class"
          item-text="value"
          item-value="code"
          placeholder="Select Microservice Class"
          @change="changeStepper(microAllData.clas)"
          required
        ></v-select>
        <span v-if="!microAllData.clas && !validForm" style="color:red;">{{ labels.MIC_CLVAL }}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.MIC_TY }}</label>
        <v-select
          outlined
          v-model="microAllData.fnType"
          :items="micro_type"
          item-text="value"
          item-value="code"
          placeholder="Select Microservice Type"
          required
        ></v-select>
        <span v-if="!microAllData.fnType && !validForm" style="color:red;">{{ labels.MIC_TYVAL }}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.MIC_URLC }}</label>
        <!-- <rich-text-editor @update="onUpdate"/> -->
        <v-text-field outlined placeholder="URL Code" v-model.trim="microAllData.urlCode"></v-text-field>
        <span v-if="!microAllData.urlCode && !validForm" style="color:red;">{{ labels.MIC_URLCVAL }}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.MIC_PROD }}</label>
        <v-select
          outlined
          v-model="microAllData.microserviceProducer"
          :items="micro_producer"
          item-text="value"
          item-value="code"
          placeholder="Select Producer"
        ></v-select>
      </v-col>
      <div class="px-4" style="width: 135px">
        <div class="d-flex flex-column" style="width: 135px">
          <label>{{ labels.MIC_ICO }}</label>
          <div class="position-relative pt-3"
            v-if="!microAllData.iconUrl && !microAllData.iconFile"
            @dragover.prevent="dragOver"
            @dragleave.prevent="dragLeave"
            @drop.prevent="drop($event, 'icon')"
          >
            <div class="text-center d-flex-column justify-center fileBox">
              <v-icon class="secondary--text"> mdi-cloud-upload</v-icon>
              <div>{{ labels.MIC_UPICO }}</div>
            </div>
            <input
              type="file"
              class="clicktofileUpload overflow-hidden w-px h-px opacity-0"
              @change="fileChange($event.target.files, 'icon')"
              accept="image/*"
            />
          </div>
        </div>
        <div v-if="microAllData.iconUrl" class="" style="position: relative">
          <button
            class="pos close-iconPos"
            type="button"
            @click="clearIcon"
            title="Remove file"
          >
            <v-icon color="error">mdi-close-circle-outline </v-icon>
          </button>
        </div>
        <img
          class="shrink rounded mx-auto fileimgWidth logoBoxSize"
          style="padding: 0px"
          v-if="microAllData.iconUrl"
          :src="microAllData.iconUrl"
          alt="Mobile Image"
          contain
          aspect-ratio="2"
        />
      </div>
    </v-row>
  </div>
</template>

<script>
import API_Util from "../../services/util/API_Util.js";
export default {
  name: "msbasicinfo",
  props: ["microAllData", "validForm", "labels"],
  data() {
    return {
      apiKey: "",
      iconlist: [],
      micro_class: [],
      micro_type: [],
      micro_producer: [],
      // selectedFile: null,
      valid: true,
      name: '',
      titleRules: [],

    };
    
  },
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.owner = localStorage.getItem("owner");
    this.getFunctionClass();
    this.getFunctionType();
    this.getFunctionProducer();
  },
  methods: {
    changeStepper() {
        this.$emit('changeStepper');
    },
    clearIcon() {
      this.microAllData.iconFile = "";
      this.microAllData.iconUrl = "";
    },
    fileChange(files, input) {
      if (input === "icon") {
        this.microAllData.iconUrl = URL.createObjectURL(files[0]);
        this.microAllData.iconFile = files[0];
        this.uploadIcons(this.microAllData.iconFile);
      }
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event, type) {
      event.preventDefault();
      this.fileChange(event.dataTransfer.files, type); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    uploadIcons(file) {
        const fd = new FormData();
        fd.append("fileToUpload", file, file.name);
        API_Util.URLS.axios
          .post(API_Util.URLS.cdnImageUpload, fd, {
            headers: {
              mimeType: "multipart/form-data",
              "x-api-key": this.apiKey,
            },
          })
          .then((response) => {
            this.microAllData.iconUrl = response.data.url;
          })
          .catch((err) => console.log(err));
    },
    async getFunctionClass() {
      var data = {
        owner_id: localStorage.getItem('owner'),
        lang: "EN",
        code: "CLASS_TYPE",
      };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
      this.micro_class = response;
    },
    async getFunctionType() {
      var data = {
        owner_id: localStorage.getItem('owner'),
        lang: "EN",
        code: "FUNCTION_TYPE",
      };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
      this.micro_type = response;
    },
    async getFunctionProducer() {
      var data = {
        owner_id: localStorage.getItem('owner'),
        lang: "EN",
        code: "MICROSERVICE_PRODUCER",
      };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
      this.micro_producer = response;
    },
  },
  watch: {
    microAllData: {
      handler(data) {
        this.$emit("microDataSubmitted", data);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    nameValidate() {
      if (!this.microAllData.name) {
        this.$emit('properDataSubmitted', false);
        return true;
      } else {
        this.$emit('properDataSubmitted', true);
        return false;
      }
      
    }
  }
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>