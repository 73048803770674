<template>
  <div class="msForm">
    <v-row>     
      <v-col cols="12">
        <label>{{ labels.TIT }}</label>
        <v-text-field outlined placeholder="Name" v-model.trim="demoappsAllData.name"></v-text-field>
        <span v-if="!demoappsAllData.name && !isValidForm" style="color:red;">{{ labels.TIT_VAL }}</span>
      </v-col>
      <v-col cols="12">
        <label>{{ labels.SHRT_DESC }}</label>
        <v-text-field
          outlined
          placeholder="Short Description"
          v-model.trim="demoappsAllData.desc"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <label>{{ labels.LNG_DESC }}</label>
        <v-text-field
          outlined
          placeholder="long Description"
          v-model.trim="demoappsAllData.desc2"
        ></v-text-field>
      </v-col>     
        <v-col cols="12">
        <label>{{ labels.USECASE }}</label>
           <mavon-editor language="en" v-model="demoappsAllData.demoappsUseCase" outlined
          name="input-7-1"
          rows="3" />
      </v-col>     
    </v-row>
  </div>
</template>

<script>
export default {
  name: "addDemoAppInfo",
  props: ['demoappsAllData', 'isValidForm', 'labels'],
  data: () => ({
    iconlist: [],
  }),
  methods: {
    onUpdateUseCase (text) {
      this.demoappsAllData.demoappsUseCase = text;
    },
  
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
.msForm{
  padding: 24px 40px;
}
/* ---------------------------------- mobile view ---------------------------------- */

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .msForm {
    padding: 0;
  }
}
</style>