<template>
  <div class="mbl-msform msForm">
    <v-row>
      <v-col cols="12">
        <label>{{ labels.MAN }}</label>
        <v-combobox
          outlined
          multiple
          v-model="microAllData.mandatoryFields"
          append-icon
          chips
          deletable-chips
          class="tag-input"
          name="input-7-1"
          rows="3"
        >
        </v-combobox>
      </v-col>
      <v-col cols="12" class="px-6">
        <label>{{ labels.LIMTT }}</label>
        <v-row class="mt-2 text-fields-row secondary lighten-3">
          <v-col cols="12" class="type">
              <label>Limitations</label>
            <div
              class="
                d-flex
                flex-md-row flex-sm-column
                mt-3
                justify-space-between
              "
            >
              <div class="d-flex flex-column px-2">
                <label>{{ labels.TYPE }}</label>
                <v-select
                  outlined
                  v-model="limitObj.type"
                  :items="micro_limits"
                  item-text="value"
                  item-value="code"
                  placeholder="Select Limitation Type"
                  @blur="validateLimitType"
                ></v-select>
                <span v-if="checkLimitType" style="color: red"
                  >{{ labels.TYPEVAL }}</span
                >
              </div>
              <div class="d-flex flex-column">
                <label class="ml-sm-2">{{ labels.LOW_LIM }}</label>
                <v-text-field
                  class="pl-2"
                  outlined
                  type="text"
                  v-model="limitObj.lowerLimit"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')"
                ></v-text-field>
              </div>
              <div class="d-flex flex-column">
                <label class="ml-sm-2">{{ labels.UP_LIM }}</label>
                <v-text-field
                  class="pl-2"
                  outlined
                  type="text"
                  v-model="limitObj.upperLimit"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')"
                  @blur="checkUpperLimit"
                >
                </v-text-field>
                <span class="pl-2" v-if="isUpperLimitValid" style="color: red"
                    >{{ labels.UP_LIMVAL }}</span
                  >
              </div>
              <div class="d-flex flex-column">
                <label class="ml-sm-2">{{ labels.FIEL }}</label>
                <v-text-field
                  class="pl-2"
                  outlined
                  v-model.trim="limitObj.field"
                ></v-text-field>
              </div>
              <div class="d-flex flex-column">
                <span class="ml-1"
                  ><v-btn
                    class="nocap primary white--text"
                    style="height: 40px; margin-top:21px;"
                    :disabled="
                      !limitObj.type ||
                      !limitObj.lowerLimit ||
                      !limitObj.upperLimit ||
                      !limitObj.field ||
                      isUpperLimitValid ||
                      microAllData.limitFields.find(
                        (ob) => ob['type'] === limitObj.type
                      )
                    "
                    @click.prevent="addLimits()"
                  >
                    <v-icon>mdi-plus </v-icon>
                  </v-btn></span
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="px-0 pt-6" v-if="microAllData.limitFields.length">
        <v-card>
          <v-simple-table class="text-center">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    <span class="font-weight-boald subtitle-1">{{ labels.TYPE }}</span>
                  </th>
                  <th>
                    <span class="font-weight-boald subtitle-1"
                      >{{ labels.LOW_LIM }}</span
                    >
                  </th>
                  <th>
                    <span class="font-weight-boald subtitle-1"
                      >{{ labels.UP_LIM }}</span
                    >
                  </th>
                  <th>
                    <span class="font-weight-boald subtitle-1">{{ labels.FIEL }}</span>
                  </th>

                  <th>
                    <span class="font-weight-boald subtitle-1">{{ labels.DEL }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(limit, index) in microAllData.limitFields"
                  :key="limit.type"
                >
                  <td class="grey lighten-5 text-left grey--text">
                    {{ limit.type }}
                  </td>
                  <td class="grey lighten-5 text-left grey--text">
                    {{ limit.lowerLimit }}
                  </td>
                  <td class="grey lighten-5 text-left grey--text">
                    {{ limit.upperLimit }}
                  </td>
                  <td class="grey lighten-5 text-left grey--text">
                    {{ limit.field }}
                  </td>
                  <td class="grey lighten-5 text-left grey--text">
                    <v-icon
                      @click.prevent="removeLimitation(index)"
                      color="error"
                      >mdi-trash-can
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "msValidationSettings",
  props: ["microAllData", "micro_limits", "labels"],
  data: () => ({
    apiKey: "",
    mandatory: "",
    limitObj: { id: 0 },
    deleteLimitationIds: [],
    owner: "",
    isUpperLimitValid: false,
    checkLimitType: false,
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.owner = localStorage.getItem("owner");
  },
  methods: {
    getLimitText(object) {
      return this.micro_limits.find((obj) => obj.code === object).value;
    },
    validateLimitType() {
      if (
        this.microAllData.limitFields.find(
          (ob) => ob["type"] === this.limitObj.type
        )
      ) {
        this.checkLimitType = true;
      } else {
        this.checkLimitType = false;
      }
    },
    checkUpperLimit() {
      if (parseInt(this.limitObj.upperLimit) < parseInt(this.limitObj.lowerLimit)) {
        this.isUpperLimitValid = true;
      } else {
        this.isUpperLimitValid = false;
      }
    },
    removeLimitation(idx) {
      if (this.microAllData.limitFields[idx].id > 0) {
        this.deleteLimitationIds.push(this.microAllData.limitFields[idx].id);
        this.microAllData.deleteLimitationIds = this.deleteLimitationIds;
      }
      this.microAllData.limitFields.splice(idx, 1);
    },
    clearLimitObj(id) {
      if (id > 0) {
        this.limitObj = { id: id };
      } else {
        this.limitObj = { id: 0 };
      }
    },
    addLimits() {
      this.microAllData.limitFields.push(this.limitObj);
      this.limitObj = { id: 0 };
    },
  },
  watch: {
    microAllData(data) {
      this.$emit("microDataSubmitted", data);
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>