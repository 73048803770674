<template>
  <div>
    <!-- <p>{{ currentEditingUser }}</p> -->
    <p>
      <b>{{labels.USR_DIRFUN}}:</b>
    </p>

    <v-row>
      <v-chip
        class="ma-1"
        color="tertiary1"
        dark
        small
        close
        v-for="func in currentEditingUser.functionDetails"
        :key="func"
        v-model="func.id"
        @click:close="removeFunction(func)"
      >
        {{ func.name}}
      </v-chip>
    </v-row>

    <v-row class=" mb-8 justify-space-between">
      <!-- Dropdown selection of functions -->
      <div class="mt-4" v-if="funList.length!=currentEditingUser.functionDetails.length">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined v-bind="attrs" v-on="on" class="nocap">
              {{labels.OD_USR_MANFUN}}
              <v-icon style="margin:0">
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>

          <!-- Group selection items -->
          <v-list style="max-height:300px">
            <v-list-item v-for="(func, index) in funList" :key="index" class="div-space"
            v-show="!checkExisting(func.name,currentEditingUser.functionDetails)"
            >
              <v-checkbox
                v-model="currentEditingUser.functionDetails"
                color="tertiary1"
                :value="func"
              >
                <template v-slot:label>
                  <span class="black--text">
                    {{ func.name }}
                  </span>
                </template>
              </v-checkbox>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <!-- -ABOVE- Dropdown selection of functions -->

      <!-- Cancel and Save buttons -->
      <!-- @2021 10th Mar, haven't create below 2 functions yet, not enough time -->
       <!-- Success notification -->
     <div class="col-12">
       <v-alert type="success" class="mt-4" dense v-show="success">
        {{labels.OD_UP_SUC}}
      </v-alert>
</div>
<div class="col-12 text-center">
      <div class="mt-4" v-if="currentEditingUser.functionsCount > currentEditingUser.functionDetails.length || currentEditingUser.functionsCount < currentEditingUser.functionDetails.length">
        <v-btn elevation="0" class="nocap mx-8" text @click="getUserInfo();currentEditingUser.functionDetails=[]">{{labels.ADD_CANCEL}}</v-btn>
        <v-btn
          elevation="0"
          class="nocap"
          color="tertiary2"
          dark
          @click="saveChange()"
        >
          <v-icon>mdi-sticker-check-outline</v-icon>
          {{labels.OD_SAVE_CHANGE}}
        </v-btn>
      </div>
</div>

    </v-row>

    <v-divider class="my-4"></v-divider>
    
    <!-- Functions attached from groups -->
    <p>
      <b>{{labels.OD_FUN_ATTACH}}:</b>
    </p>

    <div>
      <!-- <div
        :key="index"
        v-for="(group, index) in currentEditingUser.groupDetails"
      > -->
        <v-row class="mb-8">
          <!-- <v-col class="col-2">
            {{ group.value}}
          </v-col> -->

          <v-col class="col-12">
            <!-- Tags of functions attached from groups -->
            <v-chip
              class="ma-1"
              color="secondary darken-1"
              dark
              small
              v-for="functions in currentEditingUser.usergroupDetails"
              :key="functions"
            >
              {{ functions.value }}
            </v-chip>
          </v-col>
        </v-row>
      <!-- </div> -->
    </div>
    <!-- -ABOVE- Functions attached from groups -->
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import {
  manageUsersService,
  organisationService
} from '../../services/organisationService'

export default defineComponent({
  setup() {
    const { getassignFunctionList,functionsList,getuserInfo,updateUserDirectFunctions,organisation } = organisationService()
    const { currentEditingUser } = manageUsersService()

    const funList = ref();
    const deleteFunList = ref([]);
    const getUserInfo = async() => {
          await getuserInfo();
      }

    //**GET LABELS **/
    const labels=ref({});
    const success=ref(false);
    setTimeout(() => {
        labels.value=organisation.labels;
    }, 1000);

    //getUserInfo();
    const getAllfunctionList = async() => {
          await getassignFunctionList();
          funList.value = functionsList.assignOrgFunctions;
          //alert(JSON.stringify(funList.value));
      }
      getAllfunctionList();

    const saveChange = async() => {
      await updateUserDirectFunctions(currentEditingUser,deleteFunList.value);
      success.value=true;
      setTimeout(() => {
        success.value=false;
        getUserInfo();
      }, 2000);
    }

    const removeFunction = async(item) => {
      const index = currentEditingUser.functionDetails.indexOf(item)
        if (index !== -1) {
          currentEditingUser.functionDetails.splice(index, 1);
        }
        if(item.functionId==undefined){
          deleteFunList.value.push(item);
        }
    }

    const checkExisting = (name,dataarray) => {
      var isExist=false;
      if(dataarray!=undefined){
        dataarray.forEach((fun)=>{
          if(name==fun.name){
            isExist=true;
          }
        })
      }
      return isExist;
    }

    return {
      currentEditingUser,
      saveChange,
      getUserInfo,
      funList,
      getAllfunctionList,
      removeFunction,
      deleteFunList,
      labels,
      success,
      checkExisting
    }
  }
})
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 400px){
  .div-space{
    padding-right: 2px;
  }
}

@media only screen and (max-width: 525px){
  .v-menu__content{
    left: 60px !important;
  }
}
</style>