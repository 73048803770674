<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3 class="text-center">{{ labels.PRE_EDIT }}</h3>
        <div class="closeicon-right">
          <v-icon @click="closePreEdit" class="text-right"
            >mdi-close-circle-outline
          </v-icon>
        </div>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text class="px-6">
      <v-form lazy-validation>
        <addpredefined :preItem="preItem" :funcList="funcList" :labels="labels"/>
      </v-form>
      <div class="px-md-10">
      <v-alert
        text
        :type="response_status"
        v-if="response_msg.length"
      >
        {{ response_msg }}
      </v-alert>
      </div>
    </v-card-text>
    <v-divider class="py-2"></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      
      <div class="pb-2 pt-1">
        <v-btn class="nocap" @click="closePreEdit" text>
          <span class=""> {{ labels.CAN }}</span>
        </v-btn>
        <v-btn class="nocap primary" text @click="save">
          <span class=""> {{ labels.SAV }}</span>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import addpredefined from "./ownerpopup/addpredefined.vue";
export default {
  components: {
    addpredefined,
  },
  name: "predefinededit",
  props:['preItem', 'funcList', 'response_status', 'response_msg', 'labels'],
  data: () => ({
    checkbox: true,
    iconlist: [],
    text: "",
    itemsSource: [
      {
        text: "Nature",
        icon: "mdi-pencil-outline ",
      },
      {
        text: "Nightlife",
        icon: "mdi-pencil-outline ",
      },
    ],
    msType: ["Atomistic", "Pipeline"],
  }),
  methods: {
    predefinedEditDialog() {
      // this.msDetailDialog=false;
      // this.msEdit=true;
      this.$emit("closeDetailDialog", false);
    },
    closePreEdit(){
      this.$emit('closePreEdit', false);
    },
    save(){
      this.$emit('preEditedData')
    },
  },
};
</script>

<style lang="scss">
.chevron-down-circle-outline {
  color: red;
}
.v-expansion-panel-header__icon .v-icon {
  color: red !important;
}

.theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color: var(--v-secondary-darken4) !important;
  border: 1px solid var(--v-secondary-darken1) !important;
  border-radius: 100% !important;
}
</style>