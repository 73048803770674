<template>
  <div class="main-select" style="width:100%">
    <!-- Select Functions -->
    <v-row>
      <v-col class="col-12 col-md-3">
        <p class="s-input-label">{{labels.GRP_NAME}}</p>
      </v-col>
      <v-col class="col-12 col-md-9 grp-textField">
        <!-- Display selected functions in tag style -->
        <div class="mb-4" v-show="newGroup.functions[0]">
          <v-chip
            class="ma-1"
            color="tertiary1"
            dark
            small
            v-for="fun in newGroup.functions"
            :key="fun.name"
          >
            {{ fun.name }}
          </v-chip>
        </div>
        <!-- Dropdown selection of functions -->
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined v-bind="attrs" v-on="on" class="nocap grp-dd">
              {{labels.GRP_ADDFUN}}
              <v-icon style="margin:0">
                mdi-menu-right
              </v-icon>
            </v-btn>
          </template>

          <!-- Group selection items -->
          <v-list style="max-height:60vh; overflow-y:scroll">
            <v-list-item v-for="(func, index) in groupList" :key="index" v-show="!checkExisting(func.name,organisation.groups)">
              <v-checkbox
                v-model="newGroup.functions"
                color="tertiary1"
                :value="func"
              >
                <template v-slot:label>
                  <span class="black--text">
                    {{ func.name }}
                  </span>
                </template>
              </v-checkbox>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <!-- -*ABOVE*- Select Functions -->
    <!-- ========================== -->

    <!-- Success notification -->
    <v-alert type="success" class="mt-4" dense v-show="success">
      {{labels.OD_SUC_VA_GRP}}
    </v-alert>
    <v-alert
        dense
        outlined
        type="error"
        transition="fade-transition"
        v-if="groupExist"
        class="mt-2"
      >
        Group name exist!
      </v-alert>
    <!-- Control buttons -->
    <v-row class="mt-8 d-flex justify-center">
      <v-btn
        text
        color="primarytextcolor darken-1"
        class="mx-8 nocap"
        @click="toggleDialog('manageGroups_preGroup')"
      >
        {{labels.ADD_CANCEL}}
      </v-btn>
      <v-btn
        :disabled="!newGroup.functions[0]"
        :dark="newGroup.functions.length>0"
        class="mx-8 nocap"
        @click="useCreateGroup()"
        color="tertiary2"
      >
        <v-icon>mdi-account-plus-outline</v-icon>
        <span>{{labels.GRP_ADDGRP}}</span>
        <v-progress-circular
          :width="3"
          :size="15"
          color="white"
          indeterminate
          class="ml-1"
          v-if="loader"
        ></v-progress-circular>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import {
  organisationService,
  manageGroupsService
} from '../../services/organisationService.js'

export default defineComponent({
  props: {},
  setup(_, ctx) {
    //SESSION OBJECTS
    const session = ctx.root.$session,
    u_session=session.get("user_session").session;
    const { toggleDialog, getPreGroupList,preGroupsList,organisation } = organisationService()
    const { createGroup,getGroupList } = manageGroupsService()

    // Validation rules for input fields
    const rules = {
      required: (value) => !!value || labels.value.OD_REQUIRED,
      counter: (value) => value.length <= 20 || 'Max 20 characters'
    }

    // Display success notification on pop up window
    const success = ref(false)

    const newGroup = ref();

    const initNewGroup = () => {
      // Reset success state
      success.value = false

      // Reset newGroup value
      newGroup.value = {
        name: '',
        description: '',
        functions: []
      }
    }
    initNewGroup();
    const groupList=ref([]);
    const getPreGroup = async() => {
      await getPreGroupList(u_session.owner);
      groupList.value=preGroupsList.allGroupList;
    }
    getPreGroup();

    const labels=ref({});
    //**GET LABELS */
    setTimeout(() => {
      labels.value = organisation.labels;
    }, 200);
    // Call a function in organisationService.js and make API call to create new group
    const groupExist=ref(false);
    const loader=ref(false);
    const useCreateGroup = async() => {
      loader.value=true;
      // Call function in organisationService.js to make create group api call
      let response=await createGroup(newGroup.value,'PRE')
        .then((response) => response.data)
        .catch((err) => console.log(err));
        console.log(response);
        loader.value=false;
        if(response.message=='GROUP_EXIST'){
          groupExist.value=true;
        }else{
        success.value = true;
        toggleDialog('manageGroups_preGroup');
        initNewGroup();
        getGroupList();
        }

        setTimeout(function() {
          groupExist.value=false;
          success.value = false;
        },2000)
         
      // Reset newGroup to empty object, so previous edited data won't cached
    }
    const checkExisting = (name,dataarray) => {
      var isExist=false;
      if(dataarray!=undefined){
        dataarray.forEach((fun)=>{
          if(name==fun.group_name){
            isExist=true;
          }
        })
      }
      return isExist;
    };

    return {
      toggleDialog,
      rules,
      success,
      newGroup,
      useCreateGroup,
      preGroupsList,
      getPreGroupList,
      groupList,
      groupExist,
      loader,
      labels,
      organisation,
      checkExisting
    }
  }
})
</script>

<style lang="scss" scoped>
  /* ---------------------------------- mobile view ---------------------------------- */

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .grp-dd  .v-btn__content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
  }
  .grp-textField .grp-dd{
    width: 250px;
  }
  .v-menu__content--fixed {
    top: 410px !important;
    left: 60px !important;
    height: 400px;
    overflow-y: scroll;
  }
}
</style>
