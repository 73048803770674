<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>Edit and Update Flexy Value Sub List</h3>
      </div>
      <div class="closeicon-right">
                        <v-icon @click="closeSubValEdit" class="text-right"
                          >mdi-close-circle-outline
                        </v-icon>
                      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text class="px-6">     
      <v-form>
        <addflexyvaluesublist :newSubValue="newSubValue" :langItems="langItems" :isValidForm="isValidForm" :valueInfo="valueInfo"/>
      </v-form>
    </v-card-text>
    <v-divider class="py-2"></v-divider>
    <div class="px-md-10">
      <v-alert text :type="response_status" v-if="response_msg.length">
        {{ response_msg }}
      </v-alert>
    </div>
    <v-card-actions>
      <div class="pl-10">
        <v-btn class="nocap" @click="closeSubValEdit" text>
          <span class="pl-2"> Cancel</span>
        </v-btn>
        <v-btn class="nocap primary" text @click="save">
          <span class="pl-2"> Update </span>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import addflexyvaluesublist from './flexy/addflexyvaluesublist.vue';
export default {
  name:"flexyvaluesublistEdit",
  components: {
    addflexyvaluesublist,
  },  
  props: ['newSubValue', 'langItems', 'isValidForm', 'valueInfo', 'response_msg', 'response_status'],

  data: () => ({
    closeDialog: false,
    response_msg: "",
    response_status: "",
    apiKey: "",
    owner: "",
    checkbox: true,
    iconlist: [],
    text: "",
  }),
  methods: {
    created(){
      var session = this.$session.get("user_session");
      this.apiKey = session.userApiKey;
    },
    closeSubValEdit() {
      this.$emit('closeSubValEdit', false);
    },
    save(){
      this.$emit('save');
    },
  }
};
</script>

<style lang="scss">
.chevron-down-circle-outline {
  color: red;
}
.v-expansion-panel-header__icon .v-icon {
  color: red !important;
}

.theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color:  var(--v-secondary-darken4) !important;
  border: 1px solid  var(--v-secondary-darken1) !important;
  border-radius: 100% !important;
}
</style>