<template>
    <div class="msForm">
        <v-row class="px-3">
            <v-col cols="12" class="px-6 secondary lighten-3">
                <v-row class="text-fields-row secondary lighten-3">
                    <v-col cols="12" class="secondary lighten-3 pt-0 px-0">
                        <div class="d-flex align-center">
                            <v-row class="text-fields-row px-0" v-if="regmicroalldata.endpoint == ''">
                                <v-col cols="12" sm="3">
                                    <label>{{ labels.TSCODE }}</label>
                                    <v-text-field outlined disabled="true" v-model="regmicroalldata.teamspace_code"
                                        class="mt-3"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <label>{{ labels.MSCODE }}</label>
                                    <v-text-field outlined :disabled="regmicroalldata.id == 0 ? false : true"
                                        v-model.trim="regmicroalldata.microservice_code" class="mt-3"></v-text-field>
                                </v-col>
                                <v-col class="" cols="12" sm="6">
                                    <label class="ml-1">{{ labels.RT }}</label>
                                    <v-radio-group v-model="regmicroalldata.result_type"
                                        :disabled="regmicroalldata.id == 0 ? false : true" row class="mt-0">
                                        <v-radio label="getresults" value="getresults"></v-radio>
                                        <v-radio label="getpredictions" value="getpredictions"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" sm="3" class="icon_relates">
                                    <label>{{ labels.MAJVER }}</label>
                                    <v-text-field outlined v-model.trim="regmicroalldata.major"
                                        :disabled="regmicroalldata.id == 0 ? false : true"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');" class="mt-3"></v-text-field>
                                    <div class="pl-5 pt-3 tool-tip-icon icon_position">
                                        <v-tooltip top max-width="200">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon dark v-bind="attrs" v-on="on">
                                                    fa-question-circle
                                                </v-icon>
                                            </template>
                                            <span>{{ labels.MAJVER_MSG }}</span>
                                        </v-tooltip>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="3" class="icon_relates">
                                    <label>{{ labels.MINVER }}</label>
                                    <v-text-field outlined v-model.trim="regmicroalldata.minor"
                                        oninput="this.value=this.value.replace(/[^0-9]/g,'');" class="mt-3"></v-text-field>
                                    <div class="pl-5 pt-3 tool-tip-icon icon_position">
                                        <v-tooltip top max-width="200">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon dark v-bind="attrs" v-on="on">
                                                    fa-question-circle
                                                </v-icon>
                                            </template>
                                            <span>{{ labels.MINVER_MSG }}</span>
                                        </v-tooltip>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="3" class="icon_relates">
                                    <label>{{ labels.PATVER }}</label>
                                    <div class="patchInput">
                                        <v-text-field outlined v-model.trim="regmicroalldata.patch"
                                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                            class="mt-3"></v-text-field>
                                        <div class="pl-5 pt-3 tool-tip-icon icon_position">
                                            <v-tooltip top max-width="200">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon dark v-bind="attrs" v-on="on">
                                                        fa-question-circle
                                                    </v-icon>
                                                </template>
                                                <span>{{ labels.PATVER_MSG }}</span>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="2" lg="2">
                                    <div class="d-flex flex-column px-2">
                                        <span class="ml-2">
                                            <v-btn class="nocap primary white--text" style="height: 41px; margin-top:32px;"
                                                :disabled="
                                                    !regmicroalldata.major ||
                                                    !regmicroalldata.minor ||
                                                    !regmicroalldata.patch ||
                                                    !regmicroalldata.teamspace_code ||
                                                    !regmicroalldata.microservice_code ||
                                                    !regmicroalldata.result_type
                                                " @click.prevent="addEndPoint">
                                                <v-icon>mdi-link-plus </v-icon>
                                            </v-btn></span>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="12" class="pl-0 mt-n1 pt-0 endpoint-sec" v-if="regmicroalldata.endpoint.length">
                        <label>
                            {{ labels.ENDPOINT }}
                            <a style="color:#4B9ACC !important; text-decoration: underline;">
                                {{ regmicroalldata.endpoint }}
                            </a>
                        </label>
                        <v-icon style="color:#dd9e38;" class="text-right ml-4" @click="editEndPoint">
                            mdi-square-edit-outline
                        </v-icon>
                        <v-icon style="color:#B22222;" class="text-right ml-4" @click="removeEndpoint"
                            v-if="regmicroalldata.id == 0">
                            mdi-trash-can-outline
                        </v-icon>
                    </v-col>
                </v-row>
            </v-col>
            <div class="mt-5">
                <span text :type="response_status" style="color:red" v-if="response_message.length && !validForm">
                    {{ response_message }}
                </span>
            </div>
        </v-row>
    </div>
</template>
  
<script>
export default {
    name: "regApiDetails",
    props: ["regmicroalldata", "validForm", "labels", "response_message"],
    data: () => ({
        owner: "",
        enableEditing: false,
        uniqueRequest: false,
        pricingObj: { id: 0, tier: [] },
        desc: "",
        urlendpoint: "",
        date: "",
    }),
    created() {
        var sessionObj = this.$session.get("user_session");
        this.apiKey = sessionObj.session.userApiKey;
        this.owner = localStorage.getItem("owner");
        if (this.regmicroalldata.id == 0) {
            this.regmicroalldata.result_type = "getresults";
        }
    },
    methods: {
        async Dates() {
            var todaydate = new Date();
            var current_month = new Date(todaydate.setMonth(todaydate.getMonth() + 1));
            var day = ("0" + current_month.getDate()).slice(-2);
            var month = ("0" + (current_month.getMonth())).slice(-2);
            this.date = current_month.getFullYear() + "-" + (month) + "-" + (day);
        },
        addEndPoint() {
            this.regmicroalldata.version = "v" + this.regmicroalldata.major + "." + this.regmicroalldata.minor + "." + this.regmicroalldata.patch;
            this.regmicroalldata.endpoint = "https://apis.sentient.io/microservices/custommodels/" + "ts" + this.regmicroalldata.teamspace_code + this.regmicroalldata.microservice_code + "/" + "v" + this.regmicroalldata.major + "/" + this.regmicroalldata.result_type;
        },
        editEndPoint() {
            this.regmicroalldata.endpoint = "";
        },
        removeEndpoint() {
            this.regmicroalldata.endpoint = "";
            this.regmicroalldata.microservice_code = "";
            this.regmicroalldata.result_type = "getresults";
            this.regmicroalldata.major = "";
            this.regmicroalldata.minor = "";
            this.regmicroalldata.patch = "";
        },
    },
};
</script>
  
<style scoped>
.v-input__slot {
    min-height: 40px !important;
}

.endpoint-sec label {
    display: inline-block;
    width: 87%;
}

.width {
    width: 180px;
}

.type {
    padding: 12px 0;
}

.main-version .v-badge {
    left: 8px;
    top: -2px;
}

.mstable .v-data-table__wrapper::-webkit-scrollbar {

    height: 7px;
}

.mstable .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background-color: #CBAC81;
    border-radius: 4px;
}

.icon_relates {
    position: relative;
}

.icon_position {
    position: absolute;
    top: -1px;
    right: 50px;
}

.tool-tip-icon {
    font-size: 20px !important;
    color: var(--v-primarytextcolor-darken1) !important;
    cursor: pointer !important;
}

::v-deep .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 0 !important;
}
</style>
