<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3 class="text-center"> {{labels.REGMSD}}</h3>
        <div class="closeicon-right">
          <v-icon  @click.prevent="closeMsDetailDialog" class="text-right">
            mdi-close-circle-outline
          </v-icon>
        </div>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text>
      <div class="px-10 msForm">
        <!-- -----------Microservice  Details------------>
        <v-col cols="12" class="px-0 py-6">
          <h2>{{ labels.MSD }}</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
            style="border: 1px solid var(--v-secondary-lighten2) !important;
              border-radius: 5px;
          "
        >
          <v-col cols="6">
            <label class="my-4">{{labels.MSNAME}}</label>
            <div>{{ regmicroalldata.name }}</div>
          </v-col>
          <v-col cols="6">
            <label class="my-4">{{ labels.DEPTYPE }}</label>
            <div>{{ regmicroalldata.deployment_type }}</div>
          </v-col>
          <v-col cols="6">
            <label class="my-4">{{ labels.MSCODE }}</label>
            <div>{{ regmicroalldata.microservice_code }}</div>
          </v-col>
          <v-col cols="6">
            <label class="my-4">{{ labels.DESC }}</label>
            <div>{{ regmicroalldata.description }}</div>
          </v-col>
          <v-col cols="6"  v-if="tabstatus == 'approved'">
            <label class="my-4">{{ labels.MODSTATUS }}</label>
            <div>{{ regmicroalldata.model_status }}</div>
          </v-col>
          <!------------------Resource Details ------------------->
          <v-col cols="12">
            <h4 class="my-4">{{ labels.RESD }}</h4>
          </v-col>
          <v-col cols="4">
            <label class="my-4">{{labels.CPU}}</label>
            <div>{{ regmicroalldata.resource_cpu }}</div>
          </v-col>
          <v-col cols="4">
            <label class="my-4">{{ labels.MEM }}</label>
            <div>{{ regmicroalldata.resource_memory }}</div>
          </v-col>
          <v-col cols="4">
            <label class="my-4">{{ labels.GPU }}</label>
            <div>{{ regmicroalldata.resource_gpu }}</div>
          </v-col>
          <v-col cols="4">
            <label class="my-4" >{{ labels.EV }}</label>
            <div v-for="item in regmicroalldata.env_var" :key="item">
              <span>{{ item }}</span>
            </div>
          </v-col>
          <v-col cols="4">
            <label class="my-4">Port</label>
            <div>{{ regmicroalldata.model_image_port }}</div>
          </v-col>
        </v-row>
        <!-- API Details -->
        <v-col cols="12" class="px-0 py-6">
          <h2>{{labels.APID}}</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
        <v-col cols="12">
            <label class="my-4">{{ labels.EP }}</label>
            <div>{{ regmicroalldata.endpoint }}</div>
          </v-col>
        </v-row>
        <!-- Documentation Details -->
        <!-- <v-col cols="12" class="px-0 py-6">
          <h2>Documentation Details</h2>
        </v-col> -->
        <!-- <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
        <v-col cols="12">
            <label class="my-4">Docs YAML File</label>
            <div>{{ regmicroalldata.docs_yaml }}</div>
          </v-col>
        </v-row> -->
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions v-if="tabstatus !='approved'">
      <div class="py-4">
      <v-btn class="nocap" @click.prevent="closeMsDetailDialog" text>
        <span class="">{{ labels.CANCEL }}</span>
      </v-btn>
      <v-btn class="nocap primary" text @click="editDialog()">
        <span class="">
          <v-icon>mdi-square-edit-outline</v-icon>
          {{ labels.EDIT }}
        </span>
      </v-btn>
      </div>
    </v-card-actions>   
  </v-card>
</template>

<script>
export default {
  name: "registerMsDetailPage",
  props: ['regmicroalldata','regmsdetaildialog','regmseditdialog','labels','tabstatus'],
  data: () => ({
  }),
  methods: {
    closeMsDetailDialog() {
      this.$emit('closeMsDetailDialog', false);
    },
    editDialog(){
      this.regmsdetaildialog = false;
      this.regmseditdialog = true;
      this.$emit('editDialog', false);
    },
    onUpdate(text) {
      this.text = text;
    },
    getLimitText(object) {
      let limitObj =  this.micro_limits.find(obj => obj.code === object, object);
      return limitObj.value;
    },
  },
  computed: {
    parsedMicroSource() {
      if (this.microAllData.source) {
        let micro_source_obj = this.micro_source.find(obj=>obj.code === this.microAllData.source, this.microAllData.source);
        return micro_source_obj.value;
      } else {
        return "";
      }
    }
  }
};
</script>

<style>

</style>
