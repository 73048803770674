<template>
  <div>
    <!-- <navigation/> -->
    <div>
      <v-breadcrumbs :items="items" v-if="ownerDrop">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <div class="owner-tlt">
        <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc" v-if="ownerDrop"/>
      </div>
      <v-content class="white maincontent">
        <div class="pb-4">
          <div class="d-flex justify-space-between align-center">
            <!-- <div cols="12 px-0" v-if="ownerDrop">
              <a>
                <v-btn text class="nocap px-0" @click="$router.go(-1)">
                  <span class="grey--text">
                    <v-icon style="margin-left: -10px">mdi-arrow-left</v-icon>
                    {{ labels.BA }}
                  </span>
                </v-btn>
              </a>
            </div> -->
            <div>
              <h2 class="text-center font-weigt-bold">{{ labels.NAME }}</h2>
            </div>
            <div class="text-right">
              <v-spacer></v-spacer>
              <!-- <v-btn
                class="tertiary2 white--text nocap py-5"
                @click.stop="clearData()"
              >
                <v-icon class="pr-1 add">mdi-plus-circle</v-icon>
                Add Predefined Group
              </v-btn> -->
            </div>
          </div>
          <div
            class="
              d-flex
              flex-md-row flex-sm-column
              justify-start
              align-md-end
              justify-md-space-between">
        <div> 
          <v-chip outlined
            @click.prevent="SelectedEmpty"
            class="ma-2 white"
          v-if="selectedPredefined.length"
          >
            <v-icon>mdi-close-circle</v-icon><span>&nbsp;</span>  {{ selectedPredefined.length }} selected
          </v-chip>
        </div>
        <div>
            <v-btn text
                class="tertiary2--text nocap py-5"
                @click.stop="clearData()"
              >
                <!-- <v-icon class="pr-1 add" style="font-size:26px">mdi-plus-network-outline </v-icon> -->
                     <v-icon class="pr-1 add" style="font-size:26px">mdi-account-group-outline </v-icon>
                {{ labels.ADD_NAME }}
              </v-btn>
            <v-btn text
              @click.stop="deletetPredefined = true"
              :disabled="!selectedPredefined.length"
              class="error--text nocap py-5"
            >
              <v-icon  class="pr-1 add" style="font-size:26px">mdi-trash-can  </v-icon>
              {{ labels.DEL }}
            </v-btn>
          </div>
        <!-- </div> -->
        </div>
          <div>
            <v-data-table
             :mobile-breakpoint="0"
              v-model="selectedPredefined"
              :headers="headers"
              :items="predefinedList"
              item-key="id"
              show-select
              @click:row="getItem"
              class="elevation-1 listingTable"
            >
            </v-data-table>
          </div>
          <v-row justify="center" style="margin-bottom: 25px !important;">
            <v-form ref="form" v-model="valid">
              <v-dialog
                class="stepBystep addDialogl"
                scrollable
                v-model="addPreDialog"
                persistent
                max-width="1000px"
              >
                <v-card class="ms-title">
                  <v-card-title>
                    <div class="py-2">
                      <h3 class="text-center">{{ labels.ADD_NAME }}</h3>
                      <div class="closeicon-right">
                        <v-icon
                          @click="addPreDialog = false"
                          class="text-right"
                          >mdi-close-circle-outline
                        </v-icon>
                      </div>
                    </div>
                  </v-card-title>
                  <v-divider class="my-4"></v-divider>
                  <v-card-text style="height: 400px">
                    <div>
                      <v-col cols="12" class="basicInfo-tlt">
                        <h3>{{ labels.BAS_INFO }}</h3>
                      </v-col>
                      <div>
                        <addpredefined :preItem="preItem" :funcList="funcList" :isValidForm="isValidForm" :labels="labels" />
                      </div>
                    </div>
                    <div class="">
                      <v-alert
                      class="text-left"
                      text
                      :type="response_status"
                      v-if="response_msg.length"
                    >
                      {{ response_msg }}
                    </v-alert>
                    </div> 
                  </v-card-text>
                  <v-card-actions class="px-10 py-6 text-center">
                    <!-- <v-divider class="py-2"></v-divider>          -->
                    <v-btn
                      class="nocap"
                      large
                      text
                      @click="addPreDialog=false"
                    >
                      {{ labels.CAN }}
                    </v-btn>
                    <v-btn
                      class="nocap mx-5"
                      large
                      color="primary"
                      @click="save"
                    >
                      {{ labels.SAV }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-row>
          <!-- Success pop up -->
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="successPredefined"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-img
                    style="z-index: 1"
                    alt="sentient logo"
                    class="d-flex align-center justify-center"
                    src="@/assets/tick-mark1.svg"
                    contain
                    max-width="192"
                  ></v-img>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Success</div>
                  </v-card-title>
                  <p v-if="response_message.length">{{ response_message }}</p>
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap" color="primary" @click="successPredefined = false">Done </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- End -->
          <!-- delete pop up -->
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="deletetPredefined"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-avatar
                    color="error"
                    size="62"
                  >
                    <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                  </v-avatar>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Delete Confirmation</div>
                  </v-card-title>
                  Are you sure want to delete the selected predefined group?
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap mx-5" text @click="deletetPredefined = false">Cancel </v-btn>
                      <v-btn class="nocap mx-5" color="error" @click="deletePredefined()">Delete </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- End -->
          <!-- -------++++ predefined Code Detail Pages +++++--------->
          <v-dialog v-model="preDetailDialog" width="1000" scrollable persistent>
            <predefineddetail
              @closeDetailDialog="closeDialog"
              @closePredefinedDetail="closePredefinedDetail"
              :preItem="preItem"
              :labels="labels"
            />
          </v-dialog>
          <!-- ----------------------------- -->
          <!-- -------++++ Edit Referral Code Detail +++++--------->
          <v-dialog v-model="preEditDialog" width="1000" scrollable persistent>
            <predefinededit
              @closePreEdit="closeEdit"
              :funcList="funcList"
              :preItem="preItem"
              :response_msg="response_msg"
              :response_status="response_status"
              :labels="labels"
              @preEditedData="save"
            />
          </v-dialog>
          <!-- ----------------------------- -->
        </div>
      </v-content>
    </div>
    <loaderimg v-if="loader"/>
  </div>
</template> 
<script>
import loaderimg from "./loaderimg.vue";
import addpredefined from "./ownerpopup/addpredefined.vue";
import predefineddetail from "./predefinedlisting.vue";
import predefinededit from "./predefinededit.vue";
import API_Util from "../services/util/API_Util";
import headline from "./headline.vue";

export default {
  name: "predefined",
  components: {
    addpredefined,
    predefineddetail,
    predefinededit,
    loaderimg,
    headline
  },
  data: () => ({
    response_message: "",
    labels: {},
    selectedPredefined: [],
    funcList: [],
    response_msg: "",
    response_status: "",
    successPredefined: false,
    addPreDialog: false,
    preDetailDialog: false,
    preEditDialog: false,
    referraledit: false,
    deletetPredefined: false,
    show1: false,
    addMicroservices: false,
    singleSelect: false,
    ownerList: [],
    selectedOwner: "",
    headers: [
      {
        text: "NAME",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "DESCRIPTION", value: "desc" },
      { text: "CREATED DATE", value: "dateUpdated" },
    ],
    predefinedList: [],
    preItem: {
      name: "",
      ownerId: localStorage.getItem("owner"),
      desc: "",
      updatedBy: 2,
      functions: [],
      dateUpdated: "",
      id: 0,
    },
    assignFun: {
      id: 0,
      fId: 0,
      fName: "",
    },
    loading: false,
    search: "",
    selectedInt: [],
    selectedMs: [],
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: false,
        href: "",
      },
      {
        text: "Platform Setting - Pre-defined Groups",
        disabled: true,
        href: "",
      },
    ],
    isValidForm: true,
    ownerName: "",
    user: "",
    loader: true,
    ownerType: "",
    lang: "EN",
    ownerDrop: "",
    ownerDesc: "",
  }),

  computed: {},
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerName = sessionObj.session.ownerName;
    this.user = sessionObj.session.id;
    this.ownerType = localStorage.getItem("ownerType");
    this.selectedOwner = localStorage.getItem("ownerName");
    this.ownerDesc = localStorage.getItem('ownerDesc');
    this.owner = localStorage.getItem("owner");
    this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
    this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    this.items[1].href = "/ownersettings";
    this.getPredefinedList();
    this.getFuncListing();
    this.getOrgList();
    this.getFlexi();
  },
  watch: {
    selected(val) {
      this.enableDelete = false;
      if (val.length) {
        this.enableDelete = true;
      }
    },
  },

  methods: {
    allOwners() {
      this.$router.push("/ownerlisting", () => { window.location.reload()});
    },
    closeDialog(value) {
      this.preDetailDialog = value;
      this.preEditDialog = true;
    },
    clearObjectData() {
      this.addPreDialog = false;
      this.isValidForm = true;
    },
    closePredefinedDetail(value) {
      this.preDetailDialog = value;
    },
    closeEdit(status) {
      this.preEditDialog = status;
      this.$router.go();
    },
    changeOwner(obj) {
      this.loader = true;
      localStorage.setItem("owner", obj.ownerId);
      localStorage.setItem('ownerName', obj.ownerName);
      localStorage.setItem('ownerType', obj.ownerType);
      localStorage.setItem("redisCloud", obj.redisCloud);
      this.selectedOwner = obj.ownerName;
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
      this.$emit('ownerchange',obj.ownerType);
      this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
      this.getPredefinedList();
    },
    getOrgList() {
      var empt = {}
      API_Util.URLS.axios
        .post(API_Util.URLS.listOrg, empt, {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.ownerList = response.data.results;
          this.loader = false;
        });
    },
    getItem(obj) {
      this.preDetailDialog = true;
      this.preItem = obj;
    },
    SelectedEmpty() {
      this.selectedPredefined = [];
    },
    getPredefinedList() {
      const headers = { "x-api-key": this.apiKey };
      API_Util.URLS.axios
        .post(
          API_Util.URLS.predefinedList, localStorage.getItem("owner"),
          { headers }
        )
        .then((response) => {
          this.predefinedList = response.data.data;
          this.loader = false;
        });
    },
    getFuncListing() {
      const headers = { "x-api-key": this.apiKey };
      var data={
        "org_id":localStorage.getItem("owner")
      }
      API_Util.URLS.axios
        .post(API_Util.URLS.funcListing ,data, { headers })
        .then((response) => {
          this.funcList = response.data.result;
        });
    },
    // saveData(){
    //   var tempFunction = this.preItem.functions
    //   var modifiedFuncArray = []
    //   tempFunction.forEach((value) => {
    //     var exId = 0
    //     if(value.id > 0){exId = value.id}
    //     modifiedFuncArray.push({"fId":value.function_id, "id":exId, "fName":value.name})
    //   })
    //   this.preItem.functions = modifiedFuncArray
    // },
    save(){ 
      if (this.preItem.name == "") {
        this.isValidForm = false;
      } else {
        this.isValidForm = true;
      this.preItem.updatedBy = this.user;
      this.preItem.owner_id = localStorage.getItem('owner');
      var self = this;
      const headers = {
        "Content-Type": "application/json;charset=UTF-8",
        "x-api-key": this.apiKey,
      };
      API_Util.URLS.axios
      .post(API_Util.URLS.predefinedAdd, self.preItem, {headers})
      .then((response) => {
        var resp = response.data
        if(resp.message === "NAME EXIST ALREADY"){
          self.response_msg = "Name Already Exists";
          self.response_status = "error";
        } else{
            if(self.preItem.id > 0){
              self.response_message = "Successfully Updated";
              self.successPredefined = true;
              setTimeout(() => {
                this.preEditDialog = false;
                self.clearPredefinedData();
                self.getPredefinedList();
              }, 2000)
            }
            else{
              self.response_message = "Successfully Created";
              self.successPredefined = true;
              setTimeout(() => {
                self.addPreDialog = false;
                self.clearPredefinedData();
              self.getPredefinedList();
              }, 2000)
            }
        }
      })
      .catch((err) => console.log(err));
      }
    },
    clearPredefinedData() {
      this.preItem = {
        name: "",
        owner_id: localStorage.getItem("owner"),
        desc: "",
        updatedBy: this.user,
        functions: [],
        dateUpdated: "",
        id: 0,
      };
      // this.response_msg = "";
      this.isValidForm = true;
    },
    deletePredefined() {
      let toDelete = {};
      this.selectedPredefined.forEach(function (arrayItem) {
        toDelete["ids"] = arrayItem.id;
      });
      API_Util.URLS.axios
        .post(API_Util.URLS.deletePredefined, toDelete, {
          headers: { "x-api-key": this.apiKey },
          params: {},
        })
        .then((response) => response)
        .catch((err) => console.log(err));
      toDelete = [];
      this.selectedPredefined = [];
      this.deletetPredefined = false;
      setTimeout(() => {
        this.getPredefinedList();
      }, 300)
    },
    clearData() {
      this.preItem = {
        name: "",
        owner_id: localStorage.getItem("owner"),
        desc: "",
        updatedBy: 2,
        functions: [],
        dateUpdated: "",
        id: 0,
      };
      this.addPreDialog = true;
      this.isValidForm = true;
    },
    getFlexi() {
      API_Util.URLS.axios
        .post(
          API_Util.URLS.getlblbyscr,
          {
            owner_id: this.owner,
            scrId: "PRE_DEF",
            lang: this.lang,
          },
          { headers: { "x-api-key": this.apiKey } }
        )
        .then((response) => {
          this.labels = response.data.labels;
          // this.headers = [
          //                 {
          //                   text: this.labels.OWN_NAME,
          //                   align: "start",
          //                   value: "ownerName",
          //                 },
          //                 { text: this.labels.OWN_ORG, value: "name" },
          //                 { text: this.labels.DESC, value: "desc" },
          //                 { text: this.labels.CREATBY, value: "userName" },
          //                 { text: this.labels.DT_CREAT, value: "dateCreated", class:"nobr"},
          //                 { text: this.labels.STATUS, value: "status"},
          //               ];
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>
<style lang="scss">
.v-input__append-inner {
  margin-top: 11px !important;
}
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 46px !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
.container {
  max-width: 1224px;
}
.v-menu__content {
  background-color: white;
}
.v-chip .mdi-pencil-box-multiple {
  font-size: 21px !important;
}
.v-btn::before {
  background-color: transparent !important;
}
.v-stepper__step__step {
  height: 12px !important;
  min-width: 12px !important;
  width: 12px !important;
}

.v-stepper {
  // .v-stepper__step__step.primary {
  //     background-color: red !important;
  //     border-color: red !important;
  // }
  .v-divider {
    border-width: thin 0px 3px 0 !important;
  }

  .v-stepper__step {
    padding: 30px 0px 0px;

    hr {
      .v-stepper--alt-labels.v-stepper__header.v-divider {
        margin: 35px -80px 0 !important;
      }
    }
  }
}

//  .v-stepper__step--complete + .v-divider{
//      background-color: red !important;
//   }
.v-stepper__step__step {
  color: transparent !important;
  .v-icon {
    display: none !important;
  }
}
.v-stepper__header {
  box-shadow: none !important;
}

.ms-title {
  .v-card__title {
    text-align: center !important;
    display: block !important;
  }
}
.v-card__actions {
  text-align: center !important;
  display: block !important;
}
.basicInfo-tlt {
  padding:12px 40px;
}

/* ---------------------------------- mobile view ---------------------------------- */
@media only screen and (min-width: 350px) and (max-width: 767px) {
  .basicInfo-tlt {
  padding:12px 0px;
}
}
</style>
