<template>
  <!-- --------------------------- READ ME --------------------------- -->
  <!-- This component is created for sample alert confirmation purpose -->
  <!-- The major props is the alertName, a string that matche the keys -->
  <!-- in alerts and alertOnConfirmFunctions in organisationService.js -->
  <!--                                                                 -->
  <!-- Toggle this alert window by using function :                    -->
  <!-- toggleAlert('alertName'), here alertName will be a string       -->
  <!--                                                                 -->
  <!-- Use :buttons="false" to add customized button, but remember     -->
  <!-- to set the close dialog function when user customnized button   -->
  <!-- --------------------------------------------------------------- -->

  <div>
    <v-dialog
      persistent
      v-model="dialogs[dialogName]"
      content-class="s-css-reset"
      :max-width="maxWidth"
      scrollable
    >
      <v-card :class="checkText(confirmBtnText)=='DEL'
                    ? 'error-dialog'
                    : checkText(confirmBtnText)=='INFO'
                    ? 'warning-dialog'
                    : ''
                ">
        <!--Add classes based on condition success-dialog, warning-dialog ,error-dialog -->
        <v-row class="my-3 d-flex justify-center align-center ">
          <v-col
            class="text-center"
            :style="closeButton ? 'margin-left:40px' : ''"
          >
            <h2 class="my-3">
              <v-avatar class="confirm-avatar" size="60" v-if="checkText(confirmBtnText)!=''">
                <v-icon class="confirm-color mr-0" size="50">
                 mdi-alert-circle-outline
                </v-icon>
              </v-avatar>
              {{ dialogTitle }}
            </h2>
          </v-col>

          <v-btn
            icon
            class="mx-2"
            color="secondary lighten-1"
            @click="toggleDialog(dialogName)"
            v-show="closeButton"
          >
            <v-icon style="margin: 0px"> mdi-close </v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>

        <v-card-text>
          <v-row class="pa-4 my-4 d-flex justify-center">
            <div v-html="dialogContent"></div>

            <!-- **************************** -->
            <!-- Slot of alert window content -->
            <!-- **************************** -->
            <slot></slot>
          </v-row>

          <!-- Control buttons -->
          <div v-show="buttons">
            <v-row class="my-4 d-flex justify-center">
              <v-btn
                text
                color="primarytextcolor darken-1"
                class="mx-4 nocap"
                @click="toggleDialog(dialogName)"
              >
                Cancel
              </v-btn>
              <v-btn
                :color="checkText(confirmBtnText)=='DEL'
                    ? 'red'
                    : checkText(confirmBtnText)!='INFO'
                    ? 'tertiary2'
                    : 'tertiary1'
                "
                dark
                class="mx-4 nocap"
                :class="confirmBtnClass"
                @click="dialogOnConfirmFunctions[dialogName](onConfirmValue)"
              >
                <v-icon>{{ confirmBtnIcon }}</v-icon>
                {{ confirmBtnText }}
              </v-btn>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
// Services
import { organisationService } from "../../services/organisationService";

export default defineComponent({
  props: {
    dialogName: { type: String, default: "" },
    // Pass some variabled from outside of Alert component to organisationServices.js
    onConfirmValue: {},
    dialogTitle: { type: String, default: "Title" },
    dialogContent: { type: String, default: "" },
    confirmBtnText: { type: String, default: "Confirm" },
    confirmBtnClass: { type: String, default: "" },
    confirmBtnIcon: { type: String, default: "" },
    // Show Cancel and Confirm button
    buttons: { type: Boolean, default: false },
    // Show / Hide top right "x" close button
    closeButton: { type: Boolean, default: true },
    // Controls the max-width property of dialog
    maxWidth: { default: 560 },
  },
  setup() {
    const {
      dialogs,
      toggleDialog,
      dialogOnConfirmFunctions,
    } = organisationService();
    
    const checkText = (str) => {
      str="hello "+str;
      var isContains=false;
      var returnText="";
      var arrayDel = ['delete','deletion', 'remove'];
      var arrayOk = ['ok','reset'];

      arrayDel.forEach(function(word){
        if(str.toLowerCase().includes(word)){
          isContains=true;
          returnText="DEL";
        }
      });

      if(!isContains){
        arrayOk.forEach(function(word){
        if(str.toLowerCase().includes(word)){
          returnText="INFO";
        }
      });
      }
      return returnText;
    };
    return {
      dialogs,
      toggleDialog,
      dialogOnConfirmFunctions,
      checkText
    };
  },
});
</script>

<style lang="scss" scoped>

</style>