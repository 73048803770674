import axios from "axios";

import APIUTIL from './util/API_Util.js'


const CreditmanagementService = () => {

    // COMMON POST METHOD
    const postMethod = (apiKey, data, url) => {
        return axios.
            post(
                url,
                data,
                {
                    headers: { "Content-Type": "application/json;charset=UTF-8", "x-api-key": apiKey },
                }
            ).then((response) => response);
    }
    // COMMON POST METHOD
    // const getMethod = (apiKey, url,params) => {
    //     return axios.
    //         get(
    //             url,
    //             {
    //                 params: params,
    //                 headers: { "Content-Type": "application/json;charset=UTF-8", "x-api-key": apiKey },
    //             }
    //         ).then((response) => response);
    // }

    const updateCredit = async (apiKey, data) => {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth();
        month = month + 1;
        if (month < 10) {
        month = "0" + month;
        } 
        var day = date.getDate();
        if (day < 10) {
        day = "0" + day;
        } 
        if(data.expiryDate==""){
            data.expiryDate=year + 1 + '-' + month + '-' + day;
        }
        if(data.startDate == ""){
            data.startDate=year + '-' + month + '-' + day;
        }
        
        let creditData = {
            "id":"",
            "orgId":data.orgId,
            "acId":data.acId,
            "expiryDate":data.expiryDate,
            "description":data.description,
            "credit":parseFloat(data.credit),
            "reCredit":parseFloat(data.credit),
            "priority":0,
            "status":"A",
            "orgName" :data.orgname,
            "creditType" : data.creditType,
            "startDate" : data.startDate,
        }
        return await postMethod(apiKey, {credits:[creditData]}, APIUTIL.URLS.updatecreditexpiry);
    }

    const getList = async (apiKey, data) => {
        return await postMethod(apiKey, data, APIUTIL.URLS.listcreditexpiry);
    }

    const getCreditTypes = async (apiKey, data) => {
        return await postMethod(apiKey, data, APIUTIL.URLS.flexyListValue);
    }

    const getOrgList = async (apiKey,data) => {
        return await postMethod(apiKey,data,APIUTIL.URLS.getOrgList);
    }
    const getMainOrgList = async (apiKey, data) => {
        return await postMethod(apiKey, data, APIUTIL.URLS.listOrg );
    }

    const getOrgAccount = async (apiKey, data) => {
        return await postMethod(apiKey, data, APIUTIL.URLS.accountlist);
    }

    const usePromo = async (apiKey, data) => {
        return await postMethod(apiKey, data, APIUTIL.URLS.promoreferral);
    }

    const updateSearchHistory = (apiKey, data) => {
        data.type="TOPUP";
        data.keyword="Page view";
        return postMethod(apiKey, data, APIUTIL.URLS.searchtracking);
    }

    const filterList = async (array, searchText) => {
        if (searchText != "") {

            return array.filter((item) => {
                return searchText.includes(item.creditType)
            })
        } else {
            return array
        }
    }

    const filterAccount = async (array, searchText) => {
        array=array.filter((item) => {
            return searchText.includes(item.acType)
        })

        return array[0]
    }

    const getExpiryDate = (date) => {
        let today=new Date().toISOString().substr(0, 10);
        let expdays =  Math.floor((Date.parse(date) - Date.parse(today)) / 86400000);
        let addString = '';
        if (expdays <= 10){
            if (expdays == 0){
                addString = " (Expire Today)";
            }else if(expdays == 1){
                addString = " (Expire in "+expdays.toString()+" day)";
            }
            else{
                addString = " (Expire in "+expdays.toString()+" days)";
            }
        }
        let dateStr=date;
        let color=false;
        if(addString!=''){
            dateStr=date+addString;
            color=true;
        }
        return {date:dateStr,color:color}
    }

    return {
        updateCredit,
        getList,
        getCreditTypes,
        filterList,
        getOrgList,
        getMainOrgList,
        getOrgAccount,
        filterAccount,
        getExpiryDate,
        usePromo,
        updateSearchHistory
    }
}

export {
    CreditmanagementService
}