<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>{{ labels.EDI_PROC_DET }}</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text class="px-6">
      <v-form>
        <addpromocode
          :promoAllData="promoAllData"
          :promoType="promoType"
          :discountType="discountType"
          :creditValidityType="creditValidityType"
          :labels="labels"
        />
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <div class="px-md-10 pt-3">
      <v-alert text :type="response_status" v-if="response_message.length">
        {{ response_message }}
      </v-alert>
    </div>
    <v-card-actions>
      <div class="pl-10">
        <v-btn class="nocap" @click="cancelPromoEdit" text>
          <span class=""> {{ labels.CAN }}</span>
        </v-btn>
        <v-btn class="nocap primary" text @click="promoEditSave">
          <span class=""> {{ labels.SAV }} </span>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import addpromocode from "./ownerpopup/addpromocode.vue";
export default {
  components: {
    addpromocode,
  },
  name: "promoedit",
  props: [
    "promoAllData",
    "promoType",
    "discountType",
    "creditValidityType",
    "response_status",
    "response_message",
    "labels"
  ],
  data: () => ({
    checkbox: true,
    iconlist: [],
    text: "",
    itemsSource: [
      {
        text: "Nature",
        icon: "mdi-pencil-outline ",
      },
      {
        text: "Nightlife",
        icon: "mdi-pencil-outline ",
      },
    ],
    msType: ["Atomistic", "Pipeline"],
  }),
  methods: {
    promoEditSave() {
      this.$emit("promoEditedData", this.promoAllData);
    },
    onUpdate(text) {
      this.text = text;
    },
    cancelPromoEdit() {
      this.$emit("closePromoEdit", false);
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.chevron-down-circle-outline {
  color: red;
}
.v-expansion-panel-header__icon .v-icon {
  color: red !important;
}

.theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color: var(--v-secondary-darken4) !important;
  border: 1px solid var(--v-secondary-darken1) !important;
  border-radius: 100% !important;
}
</style>