<template>
  <div class="msForm">
    <v-row>
      <v-col cols="12" class="">
        <label >{{labels.DOCS  }}</label>
        <div>
          <div class="d-flex flex-column" style="width: 135px">
            <div class="position-relative pt-3" v-if="!regmicroalldata.docs_yaml_file && !this.regmicroalldata.docs_yaml_url"
              @dragover.prevent="dragOver" @dragleave.prevent="dragLeave" @drop.prevent="drop($event,'icon')">
              <div class="text-center d-flex-column justify-center fileBox">
                <v-icon class="secondary--text"> mdi-cloud-upload</v-icon>
                <div>Upload</div>
              </div>
              <input type="file" class="clicktofileUpload overflow-hidden w-px h-px opacity-0"
                @change="yamlFileChange($event.target.files, 'icon')" accept=".zip,.rar,.7zip,.tar,.gz"/>
            </div>
          </div>
          <!-- <div v-if="this.regmicroalldata.docs_yaml_url" class="mt-2" style="position: relative">
            <button class="pos close-iconPos" type="button" @click="clearIcon" title="Remove file">
              <v-icon color="error">mdi-close-circle-outline </v-icon>
            </button>
          </div> -->
          <p class="shrink rounded fileimgWidth logoBoxSize mt-1" style="padding: 0px"
            v-if="this.regmicroalldata.docs_yaml_url" alt="YAML file" contain aspect-ratio="2">{{ regmicroalldata.docs_yaml_url }}
            <button class="pos close-iconPos" type="button" @click="clearIcon" title="Remove file">
              <v-icon color="error">mdi-close-circle-outline </v-icon>
            </button></p>
        </div>
        <span v-if="!this.regmicroalldata.docs_yaml_url &&!validForm" style="color:red;">{{ labels.DOCSR }}</span>
        <div>
          <p @click="fileDownload()" class="mt-2 ml-n1" color="#9e9e9e">
              <v-icon class="secondary--text">
                mdi-download-outline
              </v-icon>
            {{ labels.DOWNLOAD }}
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "regDocsDetails",
  props: ['regmicroalldata', 'validForm', 'labels','uploadYaml'],
  data: () => ({
    apiKey: "",
    name: "",
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.owner = localStorage.getItem("owner");
  },
  methods: {
    clearIcon() {
      this.regmicroalldata.docs_yaml_file = "";
      this.regmicroalldata.docs_yaml = "";
      this.regmicroalldata.docs_yaml_url="";
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    yamlFileChange(files, input) {
      if (input === "icon") {
          this.regmicroalldata.docs_yaml_url= files[0].name;
          this.regmicroalldata.docs_yaml_file = files[0];
      }
    },
    drop(event,input) {
      event.preventDefault();
      this.yamlFileChange(event.dataTransfer.files, input)
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    fileDownload(){
      const url="https://cdn.sentient.io/63f448f2ea50d_sample_ai_docs.yaml";
      const link = document.createElement('a');
      // link.download = 'sample_ai_docs.yaml';
      link.href ='data:application/json; text,'+ url;
      window.open(url, 'Download')
      link.click();
    }
  },
};
</script>

<style lang="scss" scoped>
.v-input__slot {
  min-height: 40px !important;
}
.fileimgWidth {
 max-width: max-content;
  position: relative;
  margin-bottom: 0;
  width: max-content;
}
.msForm {
  padding: 0px 40px;
}
.close-iconPos {
  position: absolute;
  right: -30px;
  top: -2px;
}
/* ---------------------------------- mobile view ---------------------------------- */

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .msForm {
    padding: 0;
  }
}
</style>
