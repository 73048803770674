<template>
  <div class="mbl-msform msForm">
    <v-row>
      <v-col cols="12">
        <label>{{ labels.LST_CD }}</label>
        <v-text-field outlined placeholder="List Code" v-model.trim="newCode.code" @input="() => (newCode.code = newCode.code.toUpperCase())"></v-text-field>
        <span v-if="!newCode.code && !isValidForm" style="color:red;">{{ labels.LST_CDVAL }}</span>
      </v-col>
      <v-col cols="12">
        <label>{{ labels.LST_NM }}</label>
        <v-text-field outlined placeholder="List Name" v-model.trim="newCode.title"></v-text-field>
        <span v-if="!newCode.title && !isValidForm" style="color:red;">{{ labels.LST_NMVAL }}</span>
      </v-col>
      <v-col cols="12">
        <label>{{ labels.DESC }}</label>
        <v-textarea rows="1" outlined placeholder="Description" v-model.trim="newCode.desc"></v-textarea>
      </v-col>
     
    </v-row>
  </div>
</template>

<script>
export default {
  name: "addflexycodelist",
  props: ['newCode', 'isValidForm', 'langItems', 'labels'],
  data: () => ({
    itemsField: [],
    msType: ["Atomistic", "Pipeline"],
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
  },
  computed: {},

  methods: {},
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
.v-menu__content {
  margin-top: 0px !important;
  margin-left: 0px;
}
</style>
