<template>
  <div>
    <v-row class="align-center justify-center mb-5">
      <b class="mr-8">{{ labels.OD_API_KY }}:</b>
      <p class="mb-0">{{apiKey}}</p>
      <div>
        <span class="px-2 tool-tip-icon">
          <v-tooltip
            v-model="apiTooltip"
            bottom
            :open-on-hover="false"
            :open-on-click="false"
            :close-delay="5000"
          >
            <template  v-slot:activator="{on}">
              <span v-on="on"></span>
              <v-icon
                v-clipboard:copy="apiKey"
                :ripples="false"
                color="var(--v-primarytextcolor-darken4) code"
                dark
                @click="apiTooltipCopy();copyTooltipText=labels.COPY_TOCLIP;copyApikey()"
                @mouseover="apiTooltipCopy();copyTooltipText=labels.COPY;"
              >
                mdi-content-copy 
              </v-icon>
            </template>
            <span>
              <v-icon color="white" dark>
              </v-icon>
              {{copyTooltipText}}</span
            >
          </v-tooltip>
        </span>
      </div>
      <v-btn
        class="nocap disabled"
        v-if="isSuperAdmin"
      >
        <v-icon class="primarytextcolor--text text--darken-2">mdi-refresh</v-icon>
        {{labels.OD_REGEN_API}}
      </v-btn>
      <v-btn
        v-else
        @click="
          apikeyDialog = true;
          countDownTimer();
        "
        class="nocap"
      >
        <v-icon class="primarytextcolor--text text--darken-2">mdi-refresh</v-icon>
        {{labels.OD_REGEN_API}}
      </v-btn>
    </v-row>

    <div v-show="state.managingBySuperAdmin">
      <v-row><v-divider></v-divider></v-row>
      <v-row class="align-center justify-center mt-10">
        <div class="col-12">
          <!-- Success notification -->
          <v-alert type="success" class="mt-4" dense v-if="showRest">
            {{ labels.EM_SU_MSG_OU }}
          </v-alert>
        </div>
        <v-btn
          v-if="!state.changingPsw && !showRest"
          @click="userAction.changePsw"
          class="nocap"
          color="primarytextcolor darken-1"
          dark
        >
          <v-icon>mdi-lock-plus</v-icon>
          {{ labels.USR_CHANGEPASS }}
        </v-btn>

        <div v-else class="">
          <v-row class="s justify-center" v-if="!showRest">
            <v-col class="d-flex justify-end">
              <p class="mr-4 mt-2 nowrap text-space">{{ labels.USR_EMAIL_DEN }}:</p>
            </v-col>
            <v-col class="d-flex flex-column">
              <v-text-field
                class="justify-start"
                outlined
                dense
                full-width
                v-model="state.newPsw"
                :append-icon="setPass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="setPass ? 'text' : 'password'"
                @click:append="setPass = !setPass"
                hide-details
              />
              <small
                class="mt-1 red--text"
                style="max-width: 260px"
                v-show="!newPswIsValid && !!state.newPsw[0]"
              >
                <v-icon color="red">mdi-alert-circle-outline</v-icon>
                {{ labels.OD_PSW_SMS }}
              </small>
              <v-btn
                class="mt-3"
                small
                text
                color="tertiary1"
                @click="userAction.generatePsw"
              >
                <v-icon>mdi-lock-reset</v-icon>
                {{ labels.ADD_AUTOPASS }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="justify-center mt-5" v-if="!showRest">
            <v-btn
              elevation="0"
              class="nocap ma-4"
              text
              @click="userAction.cancel"
              >{{ labels.ADD_CANCEL }}</v-btn
            >
            <v-btn
              elevation="0"
              class="nocap ma-4"
              color="tertiary2"
              @click="saveChange()"
              :disabled="!newPswIsValid"
              :dark="newPswIsValid"
            >
              <v-icon>mdi-check-bold</v-icon>
              {{ labels.PASS_RST }}
            </v-btn>
          </v-row>
        </div>
      </v-row>
    </div>
    <v-row justify="center">
      <v-dialog
        v-model="apikeyDialog"
        max-width="800px"
        class="rounded-0"
        content-class="rounded-0"
      >
        <v-card class="pa-10 pb-6 rounded-0">
          <v-card-title class="d-inline px-0 pt-6">
            <v-avatar color="info lighten-5" size="60">
              <v-icon color="info" size="50">
                mdi-alert-circle-outline
              </v-icon>
            </v-avatar>
            <span
              class="pl-2 info--text headline font-weight-bold text-break"
              >{{labels.OD_REGEN_API_TITLE}}</span
            >
          </v-card-title>

          <v-card-text class="coust-title pb-0">
            <p class="primarytextcolor--text text--darken-3 mb-0 pt-6">
              {{labels.OD_REGEN_API_CONTENT}}
            </p>
          </v-card-text>
          <v-card-actions>
            <div class="row">
              <div class="col-6 text-center">
                <v-btn                  
                  class="text-capitalize"
                  text
                  @click="
                    apikeyDialog = false;
                    countDown = 10;
                    clearCountDown();
                  "
                >
                  {{labels.CANCEL}}
                </v-btn>
              </div>
              <div class="col-6 text-center">
                <v-btn
                  v-bind:class="countDown == 0 ? 'hide-btn' : 'show-btn'"
                  color="info lighten-3 white--text"
                  class="text-capitalize cursor-no-drop"
                >
                  {{labels.OD_REGEN_API}}
                  <span>({{ countDown }})</span>
                </v-btn>
                <v-btn
                  v-bind:class="countDown > 0 ? 'hide-btn' : 'show-btn'"
                  color="info"
                  class="text-capitalize white--text"
                  @click="
                    refreshKey();
                    successApikeyDialog = true;
                    apikeyDialog = false;
                    countDown = 10;
                    clearCountDown();
                  "
                >
                  {{labels.OD_REGEN_API}}
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">

       <!--REGEN API KEY SUCCESS  START-->
        <ConfirmBox
            box-name="regenAPIkeysuccess"
            :on-confirm-value="{}"
            :box-title="labels.OD_REGEN_API_SUC_TITLE"
            :box-content="labels.OD_REGEN_API_SUC_CONTENT"
            box-class="success-dialog"
            :confirm-btn-text="labels.OD_OK"
            confirm-btn-icon=""
        >
        </ConfirmBox>
        <!--REGEN API KEY SUCCESS END-->

    </v-row>
  </div>
</template>

<script>
import { computed, defineComponent, ref} from "@vue/composition-api";
import ConfirmBox from "../ui/ConfirmBox";
import {
  manageUsersService,
  organisationService,
} from "../../services/organisationService";

export default defineComponent({
  components: {
    ConfirmBox
  },
  setup(_, ctx) {
    const session = ctx.root.$session,
    u_session = session.get("user_session").session;
    const isSuperAdmin=ref(false);
    const apikeyDialog = ref(false);
    const successApikeyDialog = ref(false);
    const countDown = ref(10);
    let apiTooltip = ref(false);
    let setTime = null;
    const setPass = false;
    const { currentEditingUser } = manageUsersService();
    const {
      refreshApiKey,
      updatePassword,
      organisation,
      confirmBoxs,
      toggleConfirm
    } = organisationService();
    if(u_session.orgType== 'main' &&  u_session.id==currentEditingUser.details.user_id){
      isSuperAdmin.value=true;
    }
    const apiKey = ref(currentEditingUser.details.apikey);
    // variable below for demo purpose.
    // Change user password section will only show if log in as superadmin

    const state = ref({
      managingBySuperAdmin: true,
      changingPsw: false,
      newPsw: "",
    });

    //**GET LABELS */
    const labels = ref({});
    const showRest = ref(false);
    setTimeout(() => {
      labels.value = organisation.labels;
    }, 1000);

    const copyTooltipText=ref(labels.value.COPY);
    const refreshKey = async () => {
      await refreshApiKey();
      apiKey.value = currentEditingUser.details.apikey;
      toggleConfirm("regenAPIkeysuccess");
    };

    const saveChange = async () => {
      showRest.value = true;
      await updatePassword(state.value.newPsw);
      state.value.managingBySuperAdmin = true;
      state.value.changingPsw = false;
      state.value.newPsw = "";
      setTimeout(() => {
        showRest.value = false;
      }, 2000);
    };

    const newPswIsValid = computed(() => {
      // Password must contain at least 1 digit, 1 alphabet, and at least 12 characters
      // TODOS : currently v-text-field cannot remove "space" character, and password cannot have space.
      // We need a function to filter out spaces
      const pswPattern = /^(?=.*\d)(?=.*[~`!@#$%^&*()-_+={}[]|\/?:;"'<,>.])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      return pswPattern.test(state.value.newPsw);
    });

    const userAction = {
      changePsw: () => {
        state.value.changingPsw = true;
      },
      generatePsw: () => {
        // !!! New psw generator function goes here
        state.value.newPsw = Math.random().toString(36).slice(-8) + "$S";
      },
      cancel: () => {
        state.value.changingPsw = false;
        state.value.newPsw = "";
      },
    };

    function countDownTimer() {
      if (countDown.value > 0) {
        setTime = setTimeout(() => {
          countDown.value -= 1;
          countDownTimer();
        }, 1000);
      }
    }
    function clearCountDown() {
      clearTimeout(setTime);
    }
    // watch(apiTooltip.value, ()=>{
      
    //   setTimeout(() => {
    //     apiTooltip.value = false;
    //   }, 2000);
    // });
    function apiTooltipCopy() {     
       apiTooltip.value = true;
      setTimeout(() => {
        apiTooltip.value = false;
      }, 2000);
    }
    function copyApikey(){
      navigator.clipboard.writeText(apiKey.value);
      console.log(apiKey.value)
    }
    return {
      currentEditingUser,
      state,
      newPswIsValid,
      userAction,
      saveChange,
      refreshKey,
      apiKey,
      labels,
      showRest,
      setPass,
      countDown,
      apikeyDialog,
      countDownTimer,
      clearCountDown,
      successApikeyDialog,
      apiTooltip,
      apiTooltipCopy,
      confirmBoxs,
      toggleConfirm,
      copyTooltipText,
      copyApikey,
      isSuperAdmin
    };
  },
});
</script>

<style lang="scss" scoped>
.show-btn {
  display: inline-block;
}
.hide-btn {
  display: none;
}
.succ-pop {
  color: var(--v-tertiary2-base);
}
.succ-ava {
  background-color: rgba(var(--v-tertiary2-base), .25) !important;
  border-color: rgba(var(--v-tertiary2-base), .25) !important;
}
@media only screen and (max-width: 435px){
  .text-space{
    padding-right: 60px;
  }
}
@media only screen and (max-width: 765px){
  .text-break{
    word-break: break-word;
  }
}
</style>