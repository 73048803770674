<template>
  <div>
    <!-- <navigation/> -->
    <div>
      <v-breadcrumbs :items="items" v-if="ownerType === 'PM'">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <div class="owner-tlt">
        <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc"/>
      </div>
      <v-content class="white maincontent">
        <div class="pb-4">
          <div class="d-flex justify-space-between align-center">
            <!-- <div cols="12 px-0" v-if="ownerType === 'PM'">
              <a>
                <v-btn text class="nocap px-0 px-0" @click="$router.go(-1)">
                  <span class="grey--text">
                    <v-icon style="margin-left: -10px">mdi-arrow-left</v-icon>
                    {{ labels.BK }}
                  </span>
                </v-btn>
              </a>
            </div> -->
            <div>
              <h2 class="text-center font-weigt-bold">{{ labels.DISC }}</h2>
            </div>
            <div class="text-right">
              <v-spacer></v-spacer>
              <!-- <v-btn
                class="tertiary2 white--text nocap py-5"
                @click.stop="clearData"
              >
                <v-icon class="pr-1 add">mdi-plus-circle</v-icon>
                Add Discount
              </v-btn> -->
            </div>
          </div>
          <div class="d-flex flex-md-row flex-sm-column justify-start align-md-end justify-md-space-between">
        <div> 
          <v-chip outlined
            @click="makeSelectedEmpty"
            class="ma-2 white"
          v-if="selectedDis.length"
          >
            <v-icon>mdi-close-circle</v-icon><span>&nbsp;</span>  {{ selectedDis.length }} selected
          </v-chip>
        </div>
          <!----------Searching--------------------- -->
          <!-- <div>
          <div v-show="ownerType === 'PM' && false">
            <label class="secondary--text text--lighten-1">{{ labels.EDI_DISC }}</label>
            <v-combobox  class="mt-4 selectCom"
              v-model="selectedOwner"
              :items="ownerList"
              item-text="ownerName"
              item-value="ownerId"
              :search-input.sync="search"
              label="select owner"
              hide-selected
              @change="changeOwner"
              dens
              solo
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ search }}</strong
                      >". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
          </div> -->
          <!-- ---------------------------- -->
          <div>
            <v-btn text
                class="tertiary2--text nocap py-5"
                @click.stop="clearData"
              >
                <!-- <v-icon class="pr-1 add">mdi-plus-circle</v-icon> -->
                  <v-icon class="pr-1 add">mdi-percent</v-icon>
                {{ labels.AD_DISC }}
              </v-btn>
            <v-btn text
              @click.stop="deletetDiscounts = true"
              :disabled="!selectedDis.length"
              class="error--text nocap py-5"
            >
              <v-icon class="pr-1 add" style="font-size:26px"> mdi-trash-can </v-icon>
              {{ labels.DEL }}
            </v-btn>
          </div>
          </div>
          <div>
            <v-data-table
            :mobile-breakpoint="0"
              v-model="selectedDis"
              :headers="headers"
              :items="discountListing"
              item-key="id"
              show-select
              class="elevation-1 listingTable"
            >
              <template v-slot:item="{ item }">
                <tr style="cursor: pointer" @click="getItem(item)">
                  <td>
                    <v-checkbox
                      v-model="selectedDis"
                      :value="item"
                      style="margin: 0px; padding: 0px"
                      hide-details
                      multiple
                      color="dark-grey"
                    />
                  </td>
                  <td @click="disDetailDialog = true">
                    {{ item.name }}
                  </td>
                  <td @click="disDetailDialog = true">
                    {{ item.discount }}
                  </td>
                  <td @click="disDetailDialog = true">
                    {{ item.sdate }}
                  </td>
                  <td @click="disDetailDialog = true">
                    {{ item.edate }}
                  </td>
                  <td @click="disDetailDialog = true">
                    <!-- {{ item.micros}}-->
                    <span
                      @click.stop="openMS(item.micros)"
                      class="nocap py-5 text-decoration-underline"
                    >
                      {{ labels.CLK }}
                    </span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <v-row justify="center">
            <v-form ref="form" v-model="valid">
              <v-dialog
                class="stepBystep"
                scrollable
                v-model="adddiscountDialog"
                persistent
                max-width="1000px"
              >
                <v-card class="ms-title">
                  <v-card-title>
                    <div class="py-2">
                      <h3 class="text-center">{{ labels.AD_DISC }}</h3>
                    </div>
                  </v-card-title>
                  <v-divider class="my-4"></v-divider>
                  <v-card-text style="height: 400px">
                    <div>
                      <v-col cols="12" class="px-10">
                        <h3>Basic info</h3>
                      </v-col>
                      <div>
                        <adddiscount
                          :newDiscount="newDiscount"
                          :microArray="microArray"
                          :isValidForm="isValidForm"
                          :labels="labels"
                        />
                      </div>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <div class="px-md-10">
                      <v-alert 
                    text
                    :type="response_status"
                    v-if="response_msg.length"
                  >
                    {{ response_msg }}
                  </v-alert>
                  </div>
                  <v-card-actions class="px-10 py-6 text-center">
                   
                    <v-btn
                      class="nocap"
                      large
                      text
                      @click="adddiscountDialog = false"
                    >
                      {{ labels.CAN }}
                    </v-btn>
                    <v-btn
                      class="nocap mx-5"
                      large
                      color="primary"
                      @click="save()"
                    >
                       {{ labels.SAV }}
                    </v-btn>
                    
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-row>
          <!-- Success pop up -->
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="successDiscount"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-img
                    style="z-index: 1"
                    alt="sentient logo"
                    class="d-flex align-center justify-center"
                    src="@/assets/tick-mark1.svg"
                    contain
                    max-width="192"
                  ></v-img>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Success</div>
                  </v-card-title>
                  <p v-if="response_message.length">{{ response_message }}</p>
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap" color="primary" @click="successDiscount = false">Done </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- End -->
          <!-- ------------+++ show discounted microservices row +++------------------ -->
          <v-row justify="space-around">
            <v-col cols="auto">
              <v-dialog max-width="600" v-model="clicktoMS">
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="py-6">
                      <h2>{{ labels.DET }}</h2>
                      <v-spacer></v-spacer>
                      <v-icon @click="dialog.value = false"
                        >mdi-close-circle-outline
                      </v-icon>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="text-center">
                      <h3 class="mt-4 pa-2 tertiary2 lighten-1">
                        {{ labels.MIC }}
                      </h3>
                      <div class="my-4 secondary--tex" v-if="micro_list.length">
                        <div v-for="(mic, index) in micro_list" :key="index">
                          {{ mic }}
                        </div>
                      </div>
                      <div class="my-4 secondary--tex" v-else>{{ labels.NDF }}</div>
                    </v-card-text>
                    <v-divider></v-divider>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
          </v-row>
          <!-- delete pop up -->
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="deletetDiscounts"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-avatar
                    color="error"
                    size="62"
                  >
                    <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                  </v-avatar>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Delete Confirmation</div>
                  </v-card-title>
                  Are you sure want to delete the selected discounts?
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap mx-5" text @click="deletetDiscounts = false">Cancel </v-btn>
                      <v-btn class="nocap mx-5" color="error" @click="deletetDiscount()">Delete </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- End -->
          <!-- -------++++ Discount Detail Pages +++++--------->
          <v-dialog v-model="disDetailDialog" width="1000" scrollable>
            <discountdetail
              @closeDetailDialog="closeDialog"
              :newDiscount="newDiscount"
              :microArray="microArray"
              :labels="labels"
              @closeDiscountDetail="closeDiscountDetail"
            />
          </v-dialog>
          <!-- ----------------------------- -->
          <!-- -------++++ Edit Discount +++++--------->
          <v-dialog
            v-model="editCloseDialog"
            width="1000"
            scrollable
            class="d-none"
          >
            <discountedit
              :newDiscount="newDiscount"
              :microArray="microArray"
              :response_status="response_status"
              :response_msg="response_msg"
              :labels="labels"
              @closeDisEdit="closeEdit"
              @save="save"
            />
          </v-dialog>
          <!-- ----------------------------- -->
        </div>
      </v-content>
    </div>
    <loaderimg v-if="loader"/>
  </div>
</template> 
<script>
import loaderimg from "./loaderimg.vue";
import adddiscount from "./ownerpopup/adddiscount.vue";
import discountdetail from "./discountlisting.vue";
import discountedit from "./discountedit.vue";
import API_Util from "../services/util/API_Util.js";
import headline from "./headline.vue";
// import headline from "./headline.vue";

export default {
  components: {
    adddiscount,
    discountdetail,
    discountedit,
    loaderimg,
    headline,
  },
  name: "discount",
  data: () => ({
   
  ownerDesc: "",
    response_message: "",
    selectedOwner: "",
    disDetailDialog: false,
    microArray: [],
    orgId: 1,
    owner: "",
    apiKey: "",
    discountDetailDialog: false,
    adddiscountDialog: false,
    discountedit: false,
    enableDelete: false,
    successDiscount: false,
    show1: false,
    singleSelect: false,
    selectedDis: [],
    deletetDiscounts: false,
    clicktoMS: false,
    discountDrops: [],
    micro_list: [],
    editCloseDialog: false,
    newDiscount: {
      owner: localStorage.getItem("owner"),
      name: "",
      discount: 0,
      sdate: "",
      edate: "",
      updatedBy: 2,
      orgs: {},
      users: {},
      id: 0,
      micros: [],
    },
    discountListing: [],
    checkbox: false,
    response_msg: "",
    response_status: "",
    loading: false,
    search: "",
    ownerList: [],
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: false,
        href: "",
      },
      {
        text: "Marketing - Discounts",
        disabled: true,
        href: "",
      },
    ],
    isValidForm: true,
    ownerName: "",
    loader: false,
    ownerType: "",
    labels: {},
    language: "EN",
  }),
  watch: {
    selected(val) {
      this.enableDelete = false;
      if (val.length) {
        this.enableDelete = true;
      }
    },
  },

  created() {
    this.loader = true;
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerName = sessionObj.session.ownerName;
    this.user = sessionObj.session.id;
    this.ownerType = sessionObj.session.ownerType;
    this.selectedOwner = localStorage.getItem("ownerName");
    this.ownerDesc = localStorage.getItem('ownerDesc');
    this.owner = localStorage.getItem("owner");
    this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    this.items[1].href = "/ownersettings";
    this.getFlexi();
    this.getOrgList();
    this.getDiscountListing();
    this.getDiscountDrops();
  },

  methods: {
    changeOwner(obj) {
      this.loader = true;
      localStorage.setItem("owner", obj.ownerId);
      localStorage.setItem('ownerName', obj.ownerName);
      localStorage.setItem("ownerType", obj.ownerType);
      this.selectedOwner = obj.ownerName;
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
      this.$emit('ownerchange',obj.ownerType);
      this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
      this.getDiscountListing();
      this.getDiscountDrops();
    },
    async getOrgList() {
      var empt = {}
      await API_Util.URLS.axios
        .post(API_Util.URLS.listOrg, empt, {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.ownerList = response.data.results;
        });
    },
    closeDiscountDetail(value) {
      this.disDetailDialog = value;
    },
    clearObjectData() {
      this.adddiscountDialog = false;
      this.response_msg = "";
      this.isValidForm = true;
    },
    allOwners() {
      this.$router.push("/ownerlisting", () => { window.location.reload()});
    },

    closeDialog() {
      this.disDetailDialog = false;
      this.editCloseDialog = true;
    },
    openFun(type) {
      this.$router.push({ name: "popup", params: { type: type } });
    },
    nextStep() {
      if (this.currentStep < 7) {
        this.currentStep = this.currentStep + 1;
      }
    },
    backStep() {
      if (this.currentStep > 1) {
        this.currentStep = this.currentStep - 1;
      }
    },
    next() {
      this.loading = true;

      setTimeout(() => {
        this.search = "";
        this.selectedInt = [];
        this.selectedMs = [];
        this.loading = false;
      }, 2000);
    },
    makeSelectedEmpty() {
      this.selectedDis = [];
    },
    getDiscountDrops() {
      var owner_id = {"owner_id": localStorage.getItem("owner"), "org_id": this.orgId}
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.apiKey,
      };
      API_Util.URLS.axios
        .post(API_Util.URLS.discountDrops, owner_id,{headers})
        .then((response) => {
          this.microArray = response.data.results.micros;
          this.loader = false;
        });
    },
    getItem(obj) {
      this.newDiscount = obj;
    },
    getDiscountListing() {
      var owner_org = {"owner_id": localStorage.getItem("owner"), "org_id": this.orgId}
      const headers = { "x-api-key": this.apiKey };
      API_Util.URLS.axios
        .post(
          API_Util.URLS.discountList, owner_org,{ headers })
        .then((response) => {
          this.discountListing = response.data.results;
          this.loader = false;
        });
    },
    openMS(arrayValue) {
      let microList_obj;
      this.micro_list = [];
      this.clicktoMS = true;
      arrayValue.forEach((rowObj) => {
        microList_obj = this.microArray.find((obj) => obj.id == rowObj, rowObj);
        this.micro_list.push(microList_obj.name);
      });
    },
    refreshData() {
      this.newDiscount.name = "";
      this.newDiscount.discount = "";
      this.isValidForm = true;
    },
    save() {
      if (this.newDiscount.name == "") {
        this.isValidForm = false;
      } else if (this.newDiscount.discount == 0)  {
        this.isValidForm = false;
      } else {
        this.isValidForm = true;
        this.newDiscount.owner_id = this.owner;
        this.newDiscount.updatedBy = this.user;
      const headers = {
        "Content-Type": "application/json;charset=UTF-8",
        "x-api-key": this.apiKey,
      };
      var self = this;
      API_Util.URLS.axios
        .post(API_Util.URLS.discountAdd, self.newDiscount, { headers })
        .then((response) => {
          var resp = response.data;
          if (resp.message === "Discount Name Exists Already") {
            self.response_msg = "Discount Name Already Exists";
            self.response_status = "error";
            setTimeout(() => {
              self.response_msg = "";
            }, 3000)
          } else {
            if(self.newDiscount.id > 0){
              self.response_message = "Successfully Updated";
              self.successDiscount = true;
            }
            else{
              self.response_message = "Successfully Created";
              self.successDiscount = true;
            }
            setTimeout(() => {
              self.editCloseDialog = false
              self.adddiscountDialog = false;
              self.getDiscountListing();
              self.clearObjectData();
            }, 2000)
            this.$router.go();
          }
        })
        .catch((err) => console.log(err));
      }
    },
    clearDiscountData() {
      this.newDiscount = {
        owner: localStorage.getItem("owner"),
        name: "",
        discount: 0,
        sdate: "",
        edate: "",
        updatedBy: 2,
        orgs: {},
        users: {},
        id: 0,
        micros: [],
      };
      this.isValidForm = true;
    },
    closeEdit(status) {
      this.editCloseDialog = status;
    },
    closeDetail(status) {
      this.disDetailDialog = status;
    },
    deletetDiscount() {
      let toDelete = [];
      this.selectedDis.forEach(function (arrayItem) {
        toDelete.push(arrayItem.id);
      });
      API_Util.URLS.axios
        .post(API_Util.URLS.deleteDiscount, { ids: toDelete }, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response)
        .catch((err) => console.log(err));

      toDelete = [];
      this.selectedDis = [];
      this.deletetDiscounts = false
      setTimeout(() => {
        this.getDiscountListing();
        this.$router.go();
      }, 100)
    },
    clearData() {
      this.newDiscount = {
        owner: "1",
        name: "",
        discount: 0,
        sdate: "",
        edate: "",
        updatedBy: 2,
        orgs: {},
        users: {},
        id: 0,
        micros: [],
      };
      this.adddiscountDialog = true;
      this.isValidForm = true;
    },
    getFlexi() {
      API_Util.URLS.axios
        .post(
          API_Util.URLS.getlblbyscr,
          {
            owner_id: this.owner,
            scrId: "DISC",
            lang: this.language,
          },
          { headers: { "x-api-key": this.apiKey } }
        )
        .then((response) => {
          this.labels = response.data.labels;
          this.headers = [
                          {
                            text: this.labels.NM,
                            align: "start",
                            sortable: true,
                            value: "name",
                          },
                          { text: this.labels.DISCP, value: "discount" },
                          { text: this.labels.SRT_D, value: "sdate" },
                          { text: this.labels.END_D, value: "edate" },
                          { text: this.labels.DET, value: "micros" },
                        ];
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>
<style lang="scss">

.v-input__append-inner {
  margin-top: 11px !important;
}
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 46px !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
.container {
  max-width: 1224px;
}
.v-menu__content {
  background-color: white;
}
.v-chip .mdi-pencil-box-multiple {
  font-size: 21px !important;
}
.v-btn::before {
  background-color: transparent !important;
}
.v-stepper__step__step {
  height: 12px !important;
  min-width: 12px !important;
  width: 12px !important;
}

.v-stepper {
  // .v-stepper__step__step.primary {
  //     background-color: red !important;
  //     border-color: red !important;
  // }
  .v-divider {
    border-width: thin 0px 3px 0 !important;
  }

  .v-stepper__step {
    padding: 30px 0px 0px;

    hr {
      .v-stepper--alt-labels.v-stepper__header.v-divider {
        margin: 35px -80px 0 !important;
      }
    }
  }
}

//  .v-stepper__step--complete + .v-divider{
//      background-color: red !important;
//   }
.v-stepper__step__step {
  color: transparent !important;
  .v-icon {
    display: none !important;
  }
}
.v-stepper__header {
  box-shadow: none !important;
}

.ms-title {
  .v-card__title {
    text-align: center !important;
    display: block !important;
  }
}
.v-card__actions {
  text-align: center !important;
  display: block !important;
}
/* ---------------------------------- mobile view ---------------------------------- */

@media only screen and (min-width: 350px) and (max-width: 767px) {

} 
</style>
