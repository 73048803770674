<template>
  <div class="mbl-msform msForm">
    <v-row>      
      <v-col cols="12">
        <label>{{ labels.SHRT }}</label>
        <v-text-field outlined placeholder="Short Description" v-model.trim="microAllData.description1"></v-text-field>
      </v-col>
      <v-col cols="12">
        <label>{{ labels.LNG }}</label>
        <mavon-editor language="en" v-model="microAllData.description2" outlined
          name="input-7-1"
          rows="3" />
      </v-col>
      <v-col cols="12">
        <label>Global Model Explainability</label>
        <mavon-editor language="en" v-model="microAllData.global_desc" outlined
          name="input-7-1"
          rows="3"/>
      </v-col>
      <v-col cols="12">
        <label>{{ labels.USECA }}</label>
        <mavon-editor language="en" v-model="microAllData.useCase" outlined
          name="input-7-1"
          rows="3"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "msDescription",
  props: ['microAllData', 'labels'],
  data() {
    return {
      content: "<h1>Some initial content</h1>"
    };
  },
  methods: {
   
  },
  watch: {
  microAllData(data) {
    this.$emit('microDataSubmitted', data);
  }
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>