<template>
  <div class="mbl-msform msForm">
    <v-row>
      <v-col cols="12" sm="6">
        <label>{{ labels.SCR_CD }}</label>
        <v-text-field outlined placeholder="Screen Code" v-model.trim="newError.screenCode" @input="() => (newError.screenCode = newError.screenCode.toUpperCase())"></v-text-field>
        <span v-if="!newError.screenCode && !isValidForm" style="color:red;">{{ labels.SCR_CDVAL }}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.SCR_NM }}</label>
        <v-text-field outlined placeholder="Screen Name" v-model.trim="newError.screenName"></v-text-field>
        <span v-if="!newError.screenName && !isValidForm" style="color:red;">{{ labels.SCR_NMVAL }}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.ERR_CD }}</label>
        <v-text-field outlined placeholder="Error Code" v-model.trim="newError.errorCode" @input="() => (newError.errorCode = newError.errorCode.toUpperCase())"></v-text-field>
        <span v-if="!newError.errorCode && !isValidForm" style="color:red;">{{ labels.ERR_CDVAL }}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.ORD }}</label>
        <v-text-field outlined placeholder="Order Number" 
        type="text" 
        v-model="newError.orderNumber" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')"></v-text-field>
        <span v-if="!newError.orderNumber && !isValidForm" style="color:red;">{{ labels.ORDVAL }}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.TXTEN }}</label>
        <v-text-field outlined placeholder="Text(EN)" v-model.trim="newError.text"></v-text-field>
        <span v-if="!newError.text && !isValidForm" style="color:red;">{{ labels.TXTENVAL }}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.DESC }}</label>
        <v-textarea rows="1" outlined placeholder="Description" v-model.trim="newError.description"></v-textarea>
      </v-col>
      <!-- ----------------------------------  -->
      
      <v-col cols="12" class="secondary lighten-3">
        <div class="d-flex align-center">
          <v-row class="text-fields-row">
            <v-col cols="12" sm="6" lg="5">
              <label>{{ labels.LANGS }}</label>
              <v-select
                outlined
                v-model="temp.lang"
                :items="langItems"
                item-text="language"
                item-value="id"
                placeholder="Language"
                item-disabled="disable"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" lg="5">
              <label>{{ labels.TEXT }}</label>
              <v-text-field outlined v-model.trim="temp.text"></v-text-field>
            </v-col>

            <v-col class="addflexy-vcol" cols="12" sm="12" lg="2">
              <span class="mt-2">
                <v-btn class="nocap" outlined style="height: 40px"
                  ><v-icon @click.prevent="temp={id:0,}">mdi-autorenew </v-icon>
                </v-btn></span
              >
              
              <span class="mt-2 ml-2"
                ><v-btn class="nocap primary white--text" style="height: 40px">
                  <v-icon 
                  :disabled="!temp.lang || !temp.text || langs.find(o => o.lang === temp.lang)"
                  @click.stop="add">mdi-plus</v-icon>
                </v-btn></span
              >
            </v-col>
          </v-row>
        </div>
        
      </v-col>
           <v-col cols="12">
            <v-card :elevation="hover ? 24 : 2">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr v-if="newError.languages.length > 0"> 
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.TR_LA }}</span>
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.TEXT }}</span
                        >
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.DEL }}</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(ta, index) in newError.languages" :key="index">
                      <td>{{getLang(ta.lang)}}</td>
                      <td>{{ta.text}}</td>      
                       <td @click.prevent="deleteTrans(ta, index)"> 
                          <v-icon                    
                            color="error"
                            >mdi-trash-can
                          </v-icon></td>                   
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          
      <!-- ----------------------------- -->
    </v-row>
  </div>
</template>

<script>
import API_Util from '../../services/util/API_Util';
export default {
  name: "addflexyerror",
  props: ['newError', 'isValidForm', 'langItems', 'labels'],
  data: () => ({
    itemsField: [],
    msType: ["Atomistic", "Pipeline"],
    dropName: [],
    lang: "",
    text: "",
    lang_name: "",
    text_display: "",
    langs: [],
    temp: {id:0,},
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    if(this.newError.id > 0){
      if(this.newError.languages !== []){
        this.langs = this.newError.languages;
      }
    }
  },
  computed: {},

  methods: {
    add(){
      this.langItems.forEach((rowObj) => {
        if(rowObj.id == this.temp.lang){
          rowObj.disable = true
        }
      })
      this.langs.push(this.temp)
      this.temp = {id:0};
      this.newError.languages = this.langs
      this.langItems.forEach((row) => {
        this.newError.languages.forEach((obj) => {
          if(obj.lang == row.id){
            obj.lang = row.language
          }
        })
      });
    },
    deleteTrans(item, index){
      this.langs.splice(index, 1)
      this.langItems.forEach((row) => {
        if(isNaN(item.lang)){
          if(row.language == item.lang){
            row.disable = false
          }
        }
        else{
          if(row.id == item.lang){
            row.disable = false
          }
        }
      })
      var ids = {"id": item.id};
      API_Util.URLS.axios
      .post(API_Util.URLS.deleteTransError, ids,
      {
        headers : { "x-api-key" : this.apiKey }
      })
      .then((response) => response)
      .catch((err) => console.log(err));
    },

    getLang(langId){
      var lang_name = langId
      this.langItems.forEach((row) => {
        if(row.id == langId){
          row.disable = true
          lang_name = row.language
        }
      })
      return lang_name
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
.v-menu__content {
  margin-top: 0px !important;
  margin-left: 0px;
}
</style>
